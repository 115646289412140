import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  useToast,
  Box,
  Text,
  Flex,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getStatus, getUserLeave } from "../../../Redux/AppReducer/Action";

const LeaveHistory = () => {
  const { colorMode } = useColorMode();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [allLeaves, setAllLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const toast = useToast();
  const dispatch = useDispatch();
  const getallLeave = useSelector((state) => state.app.GETUSERLEAVEs || []);
  const statusData = useSelector((state) => state.app.GETSTATUSs || {});
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(getUserLeave(token));
    dispatch(getStatus(token));
  }, [dispatch, token]);

  useEffect(() => {
    // Combine leaves from statusData and getallLeave
    const combinedLeaves = [
      ...(statusData.leaves || []),
      ...getallLeave,
    ];

    setAllLeaves(combinedLeaves);
    setFilteredLeaves(combinedLeaves);
  }, [statusData, getallLeave]);

  const handleSearch = () => {
    if (fromDate && toDate) {
      const filtered = allLeaves.filter((leave) => {
        const leaveFromDate = new Date(leave.fromDate);
        const leaveToDate = new Date(leave.toDate);
        return (
          leaveFromDate >= new Date(fromDate) &&
          leaveToDate <= new Date(toDate)
        );
      });
      setFilteredLeaves(filtered);
    } else {
      // If no date is selected, show all leaves
      setFilteredLeaves(allLeaves);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  // This effect will reset the filtered view to include any newly added leaves
  useEffect(() => {
    setFilteredLeaves(allLeaves);
  }, [allLeaves]);

  return (
    <VStack
      spacing={4}
      color={"black"}
      p={4}
      margin={"auto"}
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
    >
      <Box
        p={4}
        borderWidth="1px"
        borderRadius="md"
        width="98%"
        margin={"auto"}
        mt={"20px"}
        border="2px solid #e04141"
        background={colorMode === "dark" ? "dark" : "#fae8e1"}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
      >
        <Flex mb={4}>
          <Text fontSize="2xl" fontWeight="600" color={"#e04141"}>
            Leave Status
          </Text>
        </Flex>
        <Stack
          direction="row"
          spacing={4}
          mb={4}
          color={colorMode === "dark" ? "white" : "black"}
        >
          <FormControl>
            <FormLabel>From Date</FormLabel>
            <Input
              type="date"
              borderColor={"#e04141"}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>To Date</FormLabel>
            <Input
              type="date"
              borderColor={"#e04141"}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Button
              background={"#e04141"}
              color={"#FECE01"}
              onClick={handleSearch}
              mt="30px"
            >
              Search
            </Button>
          </FormControl>
        </Stack>

        <Table borderWidth="3px" borderColor="#e04141">
          <Thead>
            <Tr>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Leave Type
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                From Date
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                To Date
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Reason
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Leave Status
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredLeaves.length > 0 &&
              filteredLeaves.map((leave) => {
                return (
                  <Tr key={leave.employeeID}>
                    <Td border="2px solid #e04141">{leave.typeOfLeave}</Td>
                    <Td border="2px solid #e04141">
                      {formatDate(leave.fromDate)}
                    </Td>
                    <Td border="2px solid #e04141">
                      {formatDate(leave.toDate)}
                    </Td>
                    <Td border="2px solid #e04141">{leave.reason}</Td>
                    <Td border="2px solid #e04141">{leave.status}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  );
};

export default LeaveHistory;
