import React from 'react'
import {NavLink, useNavigate} from 'react-router-dom';
import './Navbar.css';
import {IoIosHome} from 'react-icons/io';
import { BiBuildings, BiMoney, BiTimeFive, BiLogOutCircle} from "react-icons/bi";
import {BsFillPeopleFill} from 'react-icons/bs';
import { MdOutlineAssessment, MdWork, MdHolidayVillage } from "react-icons/md";
import {GiUpgrade, GiTakeMyMoney } from 'react-icons/gi';
import {AiFillMinusCircle} from 'react-icons/ai';
import {GrAddCircle, GrStatusGood, GrDocumentPerformance} from 'react-icons/gr';
import { IoDocumentsSharp } from "react-icons/io5";
import { IoReceiptSharp } from "react-icons/io5";
import { CgAwards } from "react-icons/cg";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Center, Flex, useToast } from '@chakra-ui/react';
import { logout } from '../../Redux/AuthReducer/Action';
import { useDispatch, useSelector } from 'react-redux';
import {store} from "../../Redux/store"
import { MdOutlinePayment } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
const AdminNavbar =()=>{
    return (
       <>
        <SectionLink name="Dashboard" to='/' icon={<IoIosHome  size={20} />} />
        <SectionLink name="Employee" to='/employee' icon={<BsFillPeopleFill  size={20} />} />
        <SectionLink name="Attendance" to='/attendancepage' icon={<MdWork  size={20} />} />
        <SectionLink name="Leave" to='/leaveapproval' icon={<MdHolidayVillage  size={20} />}></SectionLink>
        <SectionLink name="Appraisal" to='/appraisalrequest' icon={<BiMoney  size={20} />} />
        <SectionLink name="Documents" to='/documents' icon={<IoDocumentsSharp  size={20} />} />
        <SectionLink name="Payroll" to='/payroll' icon={<MdOutlinePayment  size={20} />} />
        <SectionLink name="Referral" to='/referralrequests' icon={<MdBusinessCenter  size={20} />} />
        <SectionLink name="Events" to='/events' icon={<CgAwards  size={20} />} />
        <SectionLink name="Awards & Rewards" to='/awards' icon={<CgAwards   size={20} />} />
        <SectionLink name="Complaints" to='/complaints' icon={<GrStatusGood   size={20} />} />
        <SectionLink name="Reimbursement" to='/reimbursementrequest' icon={<GiTakeMyMoney  size={20} />} />
        <SectionLink name="Ticket" to='/tickets' icon={<BsFillPeopleFill  size={20} />} />
       </>
    )
}
const UserNavbar = ()=>{
    return (
        <>
                <SectionLink name="Dashboard" to='/employeedashboard' icon={<IoIosHome />} />
                <SectionLink name="Employee" to='/employeeprofilepage' icon={<BsFillPeopleFill />} />
                <SectionLink name="Attendance" to='/attendance' icon={<MdWork />} />
                <SectionLink name="Leave" to='/leave' icon={<MdHolidayVillage />}></SectionLink>
                <SectionLink name="Appraisal" to='/hike' icon={<BiMoney />} />
                <SectionLink name="Document" to='/document' icon={<IoDocumentsSharp />} />
                <SectionLink name="PaySlip" to='/payslip' icon={<IoReceiptSharp />} />
                <SectionLink name="UAN" to='/uan' icon={<FaAngleDoubleRight />} />
                <SectionLink name="Referral" to='/referral' icon={<MdBusinessCenter />} />
                {/* <SectionLink name="Resign" to='/resign' icon={<FaSignOutAlt />} /> */}
                <SectionLink name="Awards & Rewards" to='/awardsandrewards' icon={<CgAwards />} />
                <SectionLink name="Raise a Complaint" to='/rise and complaints' icon={<GrStatusGood />} />
                <SectionLink name="Reimbursement" to='/reimbursement' icon={<GiTakeMyMoney />} />
                <SectionLink name="About Us" to='/about' icon={<BsFillPeopleFill />} />
                <SectionLink name="Ticket" to='/ticket' icon={<BsFillPeopleFill />} />
        </>
    )
}
const SectionLink = ({name,to,icon}) => {
    return(
        <NavLink
            to = {to}
            className = {({isActive}) => (isActive ? "selected" : 'notselected')}
        >
            {icon}{name}
        </NavLink>
    )
}
const Sidebar = () => {
    const dispatch = useDispatch();
    const user = useSelector((store)=>store.auth.user)
    const navigate = useNavigate();
    const toast = useToast();
    return (
        <div className="sidebar_bg">
        <div className='scrollable-sidebar'>
            <div className='sidebar-container'>
                {user.role == "user" ?  <UserNavbar/>: <AdminNavbar/> }
                    {/* {user?.employeeName!=undefined?<Flex onClick={()=> dispatch(logout(toast,navigate))}
                    className={window.location.href.includes('logout')?"selected":"notselected"}
                   textAlign={"left"} cursor={"pointer"}><Center>{<BiLogOutCircle />}</Center>logout</Flex>:null} */}
                   </div>
            </div>
        </div>
    )
}
export default Sidebar