import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  VStack,
  Center,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  Textarea,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  useToast,
  ModalFooter,
  useColorMode,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { MdCheckCircle, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeSetup2FA, GetEmpInfo } from "../../Redux/AppReducer/Action";
import Profile from "../../Components/Images/user-profile.png";

const ResignationModal = ({ isOpen, onClose }) => {
  const [reason, setReason] = useState("");
  const toast = useToast();

  const handleResignationSubmit = () => {
    if (!reason) {
      toast({
        title: "Resignation reason required.",
        description: "Please enter a reason for your resignation.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    console.log("Resignation reason:", reason);
    setReason("");
    onClose();
    toast({
      title: "Resignation submitted.",
      description: "Your resignation has been submitted successfully.",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  };

  const handleCancel = () => {
    setReason(""); // Clear the text area after canceling
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt={"150px"} width={"1000px"} height={"400px"}>
        <ModalHeader fontSize={25}>Resignation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            placeholder="Enter your reason for resignation"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleResignationSubmit}>
            Submit
          </Button>
          <Button variant="ghost" onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const EmployeeProfilePage = () => {
  const user = useSelector((store) => store.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [showQrModal, setShowQrModal] = useState(false);
  const [isQrVerified, setIsQrVerified] = useState(false);
  // const [isFirstLogin, setIsFirstLogin] = useState(true);
  // const [showOtpField, setShowOtpField] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);

  const qrCode = useSelector((state) => state.app.EMPLOYEESETUP2FAs);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const employeeinformation = useSelector((state) => state.app.GETEMPINFOs);
  console.log(employeeinformation);

  useEffect(() => {
    if (showQrModal) {
      dispatch(EmployeeSetup2FA(token));
    }
    dispatch(GetEmpInfo(token));
  }, [dispatch, token, showQrModal]);

  useEffect(() => {
    console.log("QR Code:", qrCode);
  }, [qrCode]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewProfilePic(imageUrl);
    }
  };

  const handleProfilePictureUpload = () => {
    document.getElementById("fileInput").click(); // Trigger the hidden file input click
  };
  
  function calculateYearsBetweenDates(joiningDate) {
    const startDate = new Date(joiningDate);
    const today = new Date();
    let years = today.getFullYear() - startDate.getFullYear();
    let months = today.getMonth() - startDate.getMonth();
    let days = today.getDate() - startDate.getDate();
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }
    if (days < 0) {
      months--;
      const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += prevMonth.getDate();
    }

    return { years, months, days };
  }

  const joiningDate = employeeinformation?.dateOfJoining;
  const timeSinceJoining = calculateYearsBetweenDates(joiningDate);

  // console.log(`Time since joining: ${timeSinceJoining.years} years, ${timeSinceJoining.months} months, and ${timeSinceJoining.days} days`);

  return (
    <Box
      mt={"70px"}
      ml={"280px"}
      color={colorMode === "dark" ? "white" : "#077777"}
      pt={"20px"}
      bg={colorMode === "dark" ? "dark" : "#fcf7d2"}
    >
      <Box mr={4} ml={6}>
        <Flex>
          <Box w={"10%"}
          position={"relative"}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          >
            <Image
              src={newProfilePic || user.profilePicture || Profile}
              alt="Profile Picture"
              aspectRatio={"unset"}
              borderRadius="full"
              objectFit={"cover"}
              box={"blue"}
              boxSize="100px"
            />
            {hovering && (
              <>
               <IconButton 
              icon={<MdEdit />}
              position={"absolute"}
              bottom={0}
              right={0}
              size={"sm"}
              onClick={handleProfilePictureUpload}
              aria-label="Edit profile picture"
              bg={"gray.600"}
              color={"white"}
              borderRadius={"full"}
              _hover={{ bg: "gray.600" }}
              />
              {/* Hidden file input */}
              <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }} // Hidden input
                />
              </>
             
            )}
          </Box>
          <Box w={"80%"} ml={"70px"}>
            <Box pb={3} borderBottom="3px solid rgba(0, 0, 0, .08)">
              <Flex>
                <Text
                  fontSize="2xl"
                  color={colorMode === "dark" ? "white" : "#000000"}
                  fontWeight="bold"
                >
                  {user?.employeeName === undefined ? (
                    <Link to="/login">Welcome, Name</Link>
                  ) : (
                    user?.employeeName
                  )}
                </Text>
                <Button
                  colorScheme="green"
                  h={"30px"}
                  mt={1}
                  leftIcon={<MdCheckCircle />}
                  ml={4}
                >
                  Active
                </Button>
              </Flex>
            </Box>
            <Box mt={1}>
              <Text
                textAlign={"left"}
                fontWeight={"bold"}
                color={colorMode === "dark" ? "white" : "#000000"}
              >
                {employeeinformation?.position}
              </Text>
            </Box>
          </Box>
          <Button onClick={onOpen} colorScheme="red" ml={8} h={"30px"}>
            Resignation
          </Button>
        </Flex>
      </Box>

      <Box
        m={5}
        p={4}
        borderRadius="10px"
        border="2px solid #e04141"
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <Flex>
          <Box w={"50%"}>
            <VStack align="start" ml={8}>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Employee ID :
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.employeeID}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Employee Name :{" "}
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.employeeName}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Gender :
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.gender}
                </Text>
              </Flex>

              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Joining Date :
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {formatDate(employeeinformation?.dateOfJoining)}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Phone Number :
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.contactInfo}
                </Text>
              </Flex>
            </VStack>
          </Box>
          <Box w={"50%"}>
            <VStack align="start" ml={8}>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  E-Mail :
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.email}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Department:{" "}
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.department}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Position :{" "}
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.position}
                </Text>
              </Flex>
              <Flex>
                <Text color={colorMode === "dark" ? "white" : "#000000"}>
                  Reporting Manager :{" "}
                </Text>
                <Text fontWeight={"bold"} ml={2} color={"#e04141"}>
                  {employeeinformation?.reportingManager}
                </Text>
              </Flex>
            </VStack>
          </Box>
        </Flex>
      </Box>
      <Box
        align="center"
        mb={6}
        ml={5}
        mr={5}
        p={8}
        border="2px solid #e04141"
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        borderRadius="10px"
      >
        <Flex>
          <Box
            w={"60%"}
            background={colorMode === "dark" ? "dark" : "#fae8e1"}
            borderRadius="10px"
            border="2px solid #e04141"
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
          >
            {/* Basic Information */}
            <Box mb={8} p={4}>
              <Heading
                as="h2"
                fontSize="xl"
                mb={8}
                align="start"
                color={colorMode === "dark" ? "white" : "#000000"}
              >
                Basic Information
              </Heading>
              <Flex>
                <Box>
                  <VStack align="start">
                    <Flex>
                      <Box>
                        <Text
                          ml={"10px"}
                          color={colorMode === "dark" ? "white" : "#000000"}
                          fontWeight={"bold"}
                          align={"left"}
                        >
                          PAN Number
                        </Text>
                        <Text
                          ml={"10px"}
                          bg={"#153448"}
                          color="white"
                          mt={3}
                          pl={2}
                          pr={2}
                          borderRadius={5}
                        >
                          {employeeinformation?.PAN}
                        </Text>
                      </Box>
                      <Box ml={"50px"}>
                        <Text
                          color={colorMode === "dark" ? "white" : "#000000"}
                          fontWeight={"bold"}
                          align={"left"}
                        >
                          Since You are Working
                        </Text>
                        <Text
                          bg={"#153448"}
                          color="white"
                          mt={3}
                          pl={2}
                          pr={2}
                          borderRadius={5}
                        >
                          {timeSinceJoining.years} years,{" "}
                          {timeSinceJoining.months} months, and{" "}
                          {timeSinceJoining.days} days.
                        </Text>
                      </Box>
                    </Flex>
                    <Flex gap={"10px"}>
                      <Box>
                        <Text
                          ml={"10px"}
                          color={colorMode === "dark" ? "white" : "#000000"}
                          fontWeight={"bold"}
                          align={"left"}
                        >
                          UAN Number
                        </Text>
                        <Text
                          ml={"10px"}
                          bg={"#153448"}
                          color="white"
                          mt={3}
                          pl={2}
                          pr={2}
                          borderRadius={5}
                        >
                          {employeeinformation?.UAN}
                        </Text>
                      </Box>
                      <Box ml={"50px"}>
                        <Text
                          color={colorMode === "dark" ? "white" : "#000000"}
                          fontWeight={"bold"}
                          align={"left"}
                        >
                          Employee Status
                        </Text>
                        <Text
                          bg={"#153448"}
                          color="white"
                          mt={3}
                          pl={2}
                          pr={2}
                          borderRadius={5}
                        >
                          {employeeinformation?.employeeStatus}
                        </Text>
                      </Box>
                    </Flex>
                  </VStack>
                </Box>
              </Flex>
            </Box>

            {/* Personal Information */}
            <Box mt={10} p={4}>
              <Heading
                as="h2"
                fontSize="xl"
                mb={8}
                align="start"
                color={colorMode === "dark" ? "white" : "#000000"}
              >
                Personal Information
              </Heading>
              <VStack align="start">
                <Flex>
                  <Box>
                    <Text color="#077777" fontWeight={"bold"} ml={"10px"}>
                      Birth Date
                    </Text>
                    <Text ml={"10px"}>
                      {formatDate(employeeinformation?.DOB)}
                    </Text>
                  </Box>
                  <Box ml={"50px"}>
                    <Text color="#077777" fontWeight={"bold"} align={"left"}>
                      Address
                    </Text>
                    <Text>{employeeinformation?.address}</Text>
                  </Box>
                </Flex>
              </VStack>
            </Box>
          </Box>
          <Box
            w={"40%"}
            ml={4}
            border="2px solid #e04141"
            borderRadius="10px"
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            background={colorMode === "dark" ? "dark" : "#fae8e1"}
          >
            <Button
              color="white"
              bg={"#153448"}
              mt={"20px"}
              onClick={() => setShowQrModal(true)}
            >
              Enable 2FA Code
            </Button>

            {!showQrModal && (
              <Box mt={"80px"}>
                <Text>Note:</Text>
                <Text>
                  Please click the above button to enable your 2FA code to set
                  up your account.
                </Text>
              </Box>
            )}

            {showQrModal && (
              <Box w={"100%"} mt={4} ml={"20px"}>
                <Text>Scan the 2FA code to set up your account.</Text>
                <Center mt={4}>
                  {qrCode ? (
                    <Image src={qrCode} alt="2FA QR Code" />
                  ) : (
                    <Box width="200px" height="200px" bg="gray.200" />
                  )}
                </Center>
                {/* Optional: Enable Button */}
                {/* <Button
        colorScheme="blue"
        mt={4}
        mb={2}
        onClick={handleQrVerification}
      >
        Enable
      </Button> */}
              </Box>
            )}

            {showVerificationMessage && isQrVerified && (
              <Text
                color="black"
                fontSize={"xl"}
                fontWeight={"bold"}
                mt={20}
                ml={"20px"}
              >
                {verificationMessage}
              </Text>
            )}
          </Box>
        </Flex>

        <Box></Box>
      </Box>
      {/* Resignation Modal */}
      <ResignationModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default EmployeeProfilePage;
