import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const link = "https://unifiedportal-mem.epfindia.gov.in/memberinterface/";
const openLinkInNewTab = () => {
  window.open(link, "_blank");
};

const UAN = () => {
  // State to store employee and employer PF deductions, UAN number, employee name, and total balance
  const [pfDeductions, setPfDeductions] = useState({});
  const [uanNumber, setUanNumber] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [totalBalance, setTotalBalance] = useState(0);

  // State for PF calculator
  const [basicSalary, setBasicSalary] = useState();
  const [yearlyHike, setYearlyHike] = useState();
  const [retirementAge, setRetirementAge] = useState();
  const [currentAge, setCurrentAge] = useState();
  const [employeePF, setEmployeePF] = useState();
  const [employerPF, setEmployerPF] = useState();
  const [totalAmountAtRetirement, setTotalAmountAtRetirement] = useState(0);

  const { colorMode } = useColorMode(); // Access the color mode

  // Function to calculate PF deductions based on basic salary
  const calculatePF = () => {
    const basic = parseFloat(basicSalary);
    const hike = parseFloat(yearlyHike);
    const retirement = parseInt(retirementAge);
    const current = parseInt(currentAge);

    if (!isNaN(basic) && !isNaN(hike) && !isNaN(retirement) && !isNaN(current)) {
      // Calculate PF contributions
      const employee = basic * 0.12; // Employee contributes 12%
      const employer = basic * 0.12; // Employer contributes 12%
      setEmployeePF(employee);
      setEmployerPF(employer);

      // Calculate total amount at retirement
      const yearsToRetirement = retirement - current;
      let totalAmount = basic;
      for (let i = 0; i < yearsToRetirement; i++) {
        totalAmount *= 1 + hike / 100;
      }
      setTotalAmountAtRetirement(totalAmount);
    }
  };

  // Function to fetch employee and employer PF deductions, UAN number, and employee name
  useEffect(() => {
    // Replace the placeholder URL with the actual API endpoint to fetch data
    const fetchData = async () => {
      const data = {
        pfDeductions: {
          employee: 2500,
          employer: 3000,
        },
        uanNumber: "123456789",
        employeeName: "John Doe",
      };

      setPfDeductions(data.pfDeductions);
      setUanNumber(data.uanNumber);
      setEmployeeName(data.employeeName);

      // Calculate the total balance by summing up employee and employer PF deductions
      const total = data.pfDeductions.employee + data.pfDeductions.employer;
      setTotalBalance(total);
    };

    fetchData();
  }, []);

  return (
    <Box minHeight="100vh" background={colorMode === "dark" ? "dark" : "#fcf7d2"}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        padding="50px"
        textAlign="center"
        mt={"70px"}
        ml={"300px"}
      >
        <Box
          bg={colorMode === "dark" ? "dark" :  "#fae8e1"}
          padding="20px"
          borderRadius="md"
          mb={6}
          width="100%"
          maxWidth="600px"
          boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
          border="2px solid #e04141"
          mr={6}
        >
          <Text fontSize={25} fontWeight="bold" color={colorMode === "dark" ? "white" : "black"} mb={5}>
            Calculate Your EPF Contribution
          </Text>
          <FormControl>
            <FormLabel>Basic Salary</FormLabel>
            <Input
              type="number"
              borderColor={"#e04141"}
              value={basicSalary}
              onChange={(e) => setBasicSalary(e.target.value)}
              placeholder="Enter basic salary... (Ex: ₹50000)"
              mb={4}
            />
            <FormLabel>Yearly Hike (%)</FormLabel>
            <Input
              type="number"
              borderColor={"#e04141"}
              value={yearlyHike}
              onChange={(e) => setYearlyHike(e.target.value)}
              placeholder="Enter yearly hike percentage"
              mb={4}
            />
            <FormLabel>Current Age</FormLabel>
            <Input
              type="number"
              borderColor={"#e04141"}
              value={currentAge}
              onChange={(e) => setCurrentAge(e.target.value)}
              placeholder="Enter current age"
              mb={4}
            />
            <FormLabel>Retirement Age</FormLabel>
            <Input
              type="number"
              borderColor={"#e04141"}
              value={retirementAge}
              onChange={(e) => setRetirementAge(e.target.value)}
              placeholder="Enter retirement age"
              mb={4}
            />
            <Button background={"#e04141"} color={"#FECE01"} onClick={calculatePF}>
              Calculate
            </Button>

            <Text fontSize="lg" fontWeight="bold" color={colorMode === 'dark' ? 'white' : 'black'} mt={4}>
              Total Amount at Retirement: Rs. {totalAmountAtRetirement.toFixed(2)}
            </Text>
          </FormControl>
        </Box>

        <Box
          bg={colorMode === "dark" ? "dark" : "#fae8e1"}
          padding="20px"
          borderRadius="md"
          border="2px solid #e04141"
          boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
          mb={6}
          width="100%"
          maxWidth="400px"
          ml={6}
        >
          <Text fontSize={"16"} fontWeight={"bold"} textAlign={"left"} color={"red"}>
            Note:
          </Text>
          <Text fontSize="md" color={colorMode === "light" ? "black" : "white"} mb={4}>
            Access the UAN portal to manage your PF account, check your balance,
            and update your details. Click the button below to visit the UAN portal.
          </Text>
          <Button color={"#FECE01"} background={"#e04141"} size="lg" onClick={openLinkInNewTab}>
            Go to UAN Portal
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default UAN;
