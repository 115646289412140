import React, { useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorMode,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingEvent } from "../../Redux/AppReducer/Action";

const UpcomingEvents = () => {
  const token = useSelector((state) => state.auth.token);
  const upcomingEvents = useSelector((state) => state.app.UPCOMINGEVENTs);
  console.log(upcomingEvents);
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  useEffect(() => {
    dispatch(getUpcomingEvent(token));
  }, [dispatch, token]);

  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("black", "white");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box
      w="100%"
      background={colorMode === "dark" ? "dark.700" : "#fafad4"}
      maxHeight={"40vh"} 
      overflowY={"auto"}
      overflowX={"hidden"}
      mt={10}
      
    
    >
      <Box
        color={colorMode === "dark" ? "white" : "black"}
        fontSize={18}
        fontWeight={700}
      >
        UPCOMING EVENTS{" "}
      </Box>
      <Box mt={8}>
        <Divider/>
        <Table
          maxWidth={"100%"}
          overflowY={"scroll"}
          background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
          borderRadius="10px"
          border="2px solid #e04141"
          boxShadow={
            colorMode === "dark"
              ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
              : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
          }
         
        >
          
          <Thead>
            <Tr>
              <Th color={"#e04141"} borderBottom="2px solid #e04141">
                Title
              </Th>
              <Th color={"#e04141"} borderBottom="2px solid #e04141">
                Description
              </Th>
              <Th color={"#e04141"} borderBottom="2px solid #e04141">
                Date
              </Th>
            </Tr>
          </Thead>
          <Tbody color={colorMode === "dark" ? "white" : "black"}>
            {upcomingEvents &&
              upcomingEvents.map((event) => (
                <Tr key={event.id}>
                  <Td>{event.title}</Td>
                  <Td textAlign={"justify"}>{event.description}</Td>
                  <Td>{formatDate(event.date)}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default UpcomingEvents;
