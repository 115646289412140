import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../Images/logo2.png";
import "./Navbar.css";
import {
  Box,
  Flex,
  Text,
  Button,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Center,
} from "@chakra-ui/react";
import { logout } from "../../Redux/AuthReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { IoMdNotifications } from "react-icons/io";
import { BsChatDots } from "react-icons/bs";
import { MdWork } from "react-icons/md";
import { BiSolidLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { MdSettings } from "react-icons/md";
import ProfilePlaceholder from "../../Components/Images/user-profile.png"; 

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((store) => store.auth.user); 
  const [isToggled, setIsToggled] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const { colorMode, toggleColorMode } = useColorMode();
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    toggleColorMode();
  };

  const handleSettingClick = () => {
    setShowResetPassword(true);
  };

  const handleResetPasswordClick = () => {
    setShowResetPassword(false);
  };

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const variants = {
    hidden: {
      clipPath: "inset(0 100% 0 0)",
    },
    visible: {
      clipPath: "inset(0 0% 0 0)",
    },
  };

  return (
    <nav
      className="navbar-container"
      bg={colorMode === "light" ? "white" : "gray.800"}
    >
      <Box marginRight={"2px solid white"} w={"40%"}>
        <Text className="navbar-text">
          Welcome!{" "}
          {user?.employeeName === undefined ? (
            <Link to="/login">Login</Link>
          ) : (
            user?.employeeName
          )}
          .
          <br />
          {currentTime}
        </Text>
      </Box>
      <Box w={"20%"}></Box>
      <Box className="navbar-logo" w={"30%"}>
        <Link to="/">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
              duration: 3.5,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "loop",
              repeatDelay: 0.5,
            }}
            style={{
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "100%", height: "100%" }}
            />
          </motion.div>
        </Link>
      </Box>
      <Box w={"30%"}></Box>
      <Box w={"10%"} mr={6}>
        <Flex direction="row" justify={"space-between"}>
          <Box
            className="toggle-button-container"
            position="absolute"
            top="0.70rem"
            right="13rem"
          >
            {/* Toggle Button */}
            <Button
              onClick={handleToggle}
              variant="unstyled"
              className={`toggle-button ${
                colorMode === "light" ? "dark" : "light"
              }`}
            >
              {colorMode === "light" ? (
                <MoonIcon size={25} />
              ) : (
                <SunIcon size={25} />
              )}
            </Button>
          </Box>
          <MdWork color="black" size={25} />
          <IoMdNotifications color="black" size={25} />
          <BsChatDots color="black" size={25} />
        </Flex>
      </Box>
      <Box className="user-profile-navbar" w={"10%"}>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} variant="link" cursor="pointer">
            {/* Use user's profile picture if available, otherwise use placeholder */}
            <img className="image-profile" src={user?.profilePicture || ProfilePlaceholder} alt="Profile" />
          </MenuButton>
          <MenuList
            borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
            boxShadow="lg"
          >
            <MenuItem
              closeOnSelect={true}
              as={Link}
              to="/employeeprofilepage"
              _hover={{ bg: colorMode === "light" ? "gray.100" : "gray.700" }}
              borderBottom="1px"
              borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
            >
              <Center>{<CgProfile />}</Center>Profile
            </MenuItem>
            <Flex direction={"row-reverse"}>
              <MenuItem
                _hover={{
                  bg: colorMode === "light" ? "gray.100" : "gray.700",
                }}
                borderBottom="1px"
                borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
                onClick={handleSettingClick} 
              >
                <Center>{<MdSettings />}</Center>Setting
              </MenuItem>
              {showResetPassword && (
                <MenuItem
                  closeOnSelect={true}
                  w={""}
                  as={Link}
                  to="/settings"
                  _hover={{
                    bg: colorMode === "light" ? "gray.100" : "gray.700",
                  }}
                  borderBottom="1px"
                  borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
                  onClick={handleResetPasswordClick}
                >
                  <Center></Center>Reset Password
                </MenuItem>
              )}
            </Flex>
            <MenuItem
              onClick={() => dispatch(logout(toast, navigate))}
              _hover={{ bg: colorMode === "light" ? "gray.100" : "gray.700" }}
            >
              <Flex cursor={"pointer"} color={"black"}>
                <Center>{<BiSolidLogOutCircle />}</Center>Logout
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </nav>
  );
};

export default Navbar;
