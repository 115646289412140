import React, { useRef, useState } from "react";
import {
  Box,
  Heading,
  Select,
  Button,
  Flex,
  Text,
  HStack,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
  useColorMode,
  Textarea,
} from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { FaFileAlt, FaFilter } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { FiPlusCircle, FiMinusCircle, FiEdit } from "react-icons/fi";
import { UserReimbursement } from "../../Redux/AppReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import { format, parse } from "date-fns";

const ReimbursementPage = () => {
  const fileInputRef = useRef(null);
  const [financialYear, setFinancialYear] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [addingClaim, setAddingClaim] = useState(false);
  const [claimDate, setClaimDate] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [bills, setBills] = useState([
    { type: "", billNo: "", billDate: "", amount: "" },
  ]);
  const [claims, setClaims] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { colorMode } = useColorMode();
  const userEmployeeID = useSelector((state) => state.auth.user?.employeeID);

  const handleYearChange = (event) => {
    setFinancialYear(event.target.value);
  };

  const toggleSummary = () => {
    setShowSummary((prevState) => !prevState);
  };

  const startAddingClaim = () => {
    setAddingClaim(true);
  };

  const stopAddingClaim = () => {
    setAddingClaim(false);
    setEditingIndex(null);
    setBills([{ type: "", billNo: "", billDate: "", amount: "" }]);
  };

  const addAnotherBill = () => {
    setBills([...bills, { type: "", billNo: "", billDate: "", amount: "" }]);
  };

  const removeBill = (index) => {
    const updatedBills = bills.filter((_, i) => i !== index);
    setBills(updatedBills);
  };

  const handleBillChange = (index, field, value) => {
    const updatedBills = [...bills];
    updatedBills[index][field] = value;
    setBills(updatedBills);
  };

  const validateBills = () => {
    for (let bill of bills) {
      if (!bill.type || !bill.billNo || !bill.billDate || !bill.amount) {
        toast({
          title: "Validation Error",
          description: "Please fill out all required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleClaimDateChange = (e) => {
    setClaimDate(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateBills()) return;

    const formData = new FormData();
    formData.append("employeeID", employeeID);
    formData.append("claimDate", format(new Date(claimDate), "yyyy/MM/dd"));

    bills.forEach((bill, index) => {
      formData.append(`reimbursementType`, bill.type);
      formData.append(
        `billDate`,
        format(new Date(bill.billDate), "yyyy/MM/dd")
      );
      formData.append(`billNumber`, bill.billNo);
      formData.append(`billAmount`, bill.amount);

      if (bill.billFile) {
        formData.append(`billDocument`, bill.billFile);
      } else {
        toast({
          title: "File not selected",
          description: "Please upload a file for each bill.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    });

    try {
      const response = await dispatch(UserReimbursement(token, formData));
      toast({
        title: "Claim submitted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setClaims([...claims, response.payload.claim]);
      setEmployeeID("");
      setClaimDate("");
      setBills([{ type: "", billNo: "", billDate: "", amount: "" }]);
      stopAddingClaim();
    } catch (error) {
      toast({
        title: "Error submitting claim",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (index) => {
    setBills([claims[index]]);
    setEditingIndex(index);
    setAddingClaim(true);
  };

  const handleDelete = (index) => {
    const updatedClaims = claims.filter((_, i) => i !== index);
    setClaims(updatedClaims);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        // Example: 5MB size limit
        toast({
          title: "File is too large",
          description: "Please select a file smaller than 5MB.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const updatedBills = [...bills];
      updatedBills[index].billFile = file;
      setBills(updatedBills);
      toast({
        title: "Document Uploaded Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      mt={"70px"}
      ml={"280px"}
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
    >
      <Box p={"20px"}>
        {!addingClaim ? (
          <>
            <Flex justify="space-between" align="center" p={5}>
              <Heading
                fontSize={25}
                color={colorMode === "dark" ? "white" : "black"}
              >
                Reimbursements
              </Heading>
              <Button
                background={"#e04141"}
                color={"#FECE01"}
                onClick={startAddingClaim}
              >
                Add New Claim
              </Button>
            </Flex>
            <Box>
              <Box p={5}>
                <Flex align="center">
                  <Text textAlign={"left"} mr={8}>
                    Eligible Summary
                  </Text>
                  <FaFilter />
                  <Text ml={4}>Financial Year : </Text>
                  <Select
                    ml={2}
                    width="150px"
                    borderColor={"#e04141"}
                    value={financialYear}
                    onChange={handleYearChange}
                  >
                    <option value="">Select Year</option>
                    <option value="2020-2021">2020 - 2021</option>
                    <option value="2021-2022">2021 - 2022</option>
                    <option value="2022-2023">2022 - 2023</option>
                    <option value="2023-2024">2023 - 2024</option>
                    <option value="2024-2025">2024 - 2025</option>
                    <option value="2025-2026">2025 - 2026</option>
                    {/* Add more options as needed */}
                  </Select>
                </Flex>
              </Box>
              <Box
                border="2px solid #e04141"
                borderRadius="10px"
                boxShadow={
                  colorMode === "dark"
                    ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                    : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
              >
                <Flex p={5}>
                  <Box
                    w={"25%"}
                    mr={4}
                    p={3}
                    borderRadius="10px"
                    border={"2px solid #e04141"}
                    boxShadow={
                      colorMode === "dark"
                        ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                        : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                    }
                  >
                    <Text textAlign={"left"} fontSize={18}>
                      Total Reimbursement
                    </Text>
                    <HStack>
                      <VStack mt={3}>
                        <Text fontWeight={"bold"} fontSize={20} ml={20}>
                          20
                        </Text>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box
                    w={"25%"}
                    mr={4}
                    p={3}
                    borderRadius="10px"
                    border={"2px solid #e04141"}
                    boxShadow={
                      colorMode === "dark"
                        ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                        : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                    }
                  >
                    <Text textAlign={"left"} fontSize={18}>
                      Approved Reimbursement
                    </Text>
                    <HStack>
                      <VStack mt={3}>
                        <Text fontWeight={"bold"} fontSize={20} ml={20}>
                          5
                        </Text>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box
                    w={"25%"}
                    p={3}
                    borderRadius="10px"
                    border={"2px solid #e04141"}
                    boxShadow={
                      colorMode === "dark"
                        ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                        : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                    }
                  >
                    <Text textAlign={"left"} fontSize={18}>
                      Pending Reimbursement
                    </Text>
                    <HStack>
                      <VStack mt={3}>
                        <Text fontWeight={"bold"} fontSize={20} ml={20}>
                          10
                        </Text>
                      </VStack>
                    </HStack>
                  </Box>
                </Flex>

                <Box p={5}>
                  <Flex mb={3}>
                    <GrNotes color="#e04141" />
                    <Heading
                      color={"#e04141"}
                      fontSize={18}
                      textAlign={"left"}
                      ml={3}
                      mt={-1}
                    >
                      All Claims
                    </Heading>
                  </Flex>
                  <TableContainer>
                    <Table borderWidth="3px" borderColor="#e04141">
                      <Thead>
                        <Tr>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Claim Number
                          </Th>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Claim Date
                          </Th>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Claimed Amount
                          </Th>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Approved Amount
                          </Th>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Status
                          </Th>
                          <Th
                            border="2px solid #e04141"
                            color={"#e04141"}
                            fontSize={14}
                          >
                            Actions
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {claims.map((claim, index) => (
                          <Tr key={index}>
                            <Td
                              color={"blue"}
                              border="2px solid #e04141"
                            >{`CLAIM-${index.toString().padStart(5, "0")}`}</Td>
                            <Td border="2px solid #e04141">{claim.date}</Td>
                            <Td border="2px solid #e04141">₹{claim.amount}</Td>
                            <Td border="2px solid #e04141">₹{claim.amount}</Td>
                            <Td border="2px solid #e04141">Approved</Td>
                            <Td>
                              <Button
                                onClick={() => handleEdit(index)}
                                size="sm"
                                mr={2}
                              >
                                <FiEdit />
                              </Button>
                              <Button
                                onClick={() => handleDelete(index)}
                                size="sm"
                              >
                                <IoClose />
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            p={5}
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            borderRadius="md"
            background={colorMode === "dark" ? "dark" : "#fae8e1"}
            border="2px solid #e04141"
          >
            <Flex justify="space-between" align="center">
              <Heading
                fontSize={25}
                mb={5}
                textAlign={"left"}
                color={"#e04141"}
              >
                {editingIndex !== null ? "Edit Claim" : "New Claim"}
              </Heading>
              <Button onClick={stopAddingClaim}>
                <IoClose />
              </Button>
            </Flex>
            <form onSubmit={handleSubmit}>
              <FormControl mb={8} textAlign={"left"}>
                <FormLabel>Claim Date</FormLabel>
                <Input
                  type="date"
                  w={"auto"}
                  value={claimDate}
                  onChange={handleClaimDateChange}
                  borderColor={"#e04141"}
                  required
                />
              </FormControl>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr alignItems={"left"}>
                      <Th textAlign={"left"}>Emlployee ID</Th>
                      <Th textAlign={"left"}>Reimbursement Type</Th>
                      <Th textAlign={"left"}>Bill Date</Th>
                      <Th textAlign={"left"}>Bill Number</Th>
                      <Th textAlign={"left"}>Bill Amount</Th>
                      <Th textAlign={"left"}>Upload Bill</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {bills.map((bill, index) => (
                      <Tr key={index}>
                        <Td pl={-4}>
                          <FormControl id="EmployeeID">
                            <Select
                              borderColor={"#e04141"}
                              placeholder="Employee ID"
                              value={bill.employeeID}
                              onChange={(e) => setEmployeeID(e.target.value)}
                            >
                              <option value={userEmployeeID}>
                                {userEmployeeID}
                              </option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td >
                          <Textarea
                            type="text"
                            ml={1}
                            height="40px"
                            rows="4"
                            width="140px"
                            borderColor={"#e04141"}
                            value={bill.type}
                            onChange={(e) =>
                              handleBillChange(index, "type", e.target.value)
                            }
                          ></Textarea>
                        </Td>
                        <Td pl={-4}>
                          <Input
                            type="date"
                            w={"150px"}
                            borderColor={"#e04141"}
                            value={bill.billDate}
                            onChange={(e) =>
                              handleBillChange(
                                index,
                                "billDate",
                                e.target.value
                              )
                            }
                          />
                        </Td>
                        <Td pl={-4}>
                          <Input
                            type="text"
                            w={"100px"}
                            borderColor={"#e04141"}
                            value={bill.billNo}
                            onChange={(e) =>
                              handleBillChange(index, "billNo", e.target.value)
                            }
                          />
                        </Td>
                        <Td pl={-4}>
                          <InputGroup>
                            <InputLeftAddon
                              borderColor={"#e04141"}
                              background={"#e04141"}
                              color={"#FECE01"}
                            >
                              ₹
                            </InputLeftAddon>
                            <Input
                              type="number"
                              w={"100px"}
                              borderColor={"#e04141"}
                              value={bill.amount}
                              onChange={(e) =>
                                handleBillChange(
                                  index,
                                  "amount",
                                  e.target.value
                                )
                              }
                            />
                          </InputGroup>
                        </Td>
                        <Td pl={-4}>
                          <InputGroup>
                            <FaFileAlt
                              color="teal"
                              size={"35px"}
                              onClick={() =>
                                document
                                  .getElementById(`file-upload-${index}`)
                                  .click()
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <input
                              type="file"
                              // ref={fileInputRef}
                              onChange={(e) => handleFileChange(e, index)}
                              accept=".pdf, image/*"
                              id={`file-upload-${index}`}
                              style={{ display: "none" }}
                            />
                          </InputGroup>
                        </Td>
                        <Td pl={-4}>
                          <Text
                            onClick={() => removeBill(index)}
                            colorScheme="red"
                            w={"10px"}
                            h={"20px"}
                            cursor="pointer"
                          >
                            <FiMinusCircle color="red" />
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Flex
                ml={4}
                mb={4}
                mt={4}
                w={"150px"}
                onClick={addAnotherBill}
                cursor="pointer"
              >
                <FiPlusCircle color="#e04141" />
                <Text ml={1} mt={-1}>
                  Add Another Bill
                </Text>
              </Flex>
              <Flex mb={8}>
                <Button
                  background={"#e04141"}
                  color={"#FECE01"}
                  ml={4}
                  type="submit"
                >
                  Send for Approval
                </Button>
                <Button ml={4} onClick={stopAddingClaim}>
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReimbursementPage;
