import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApprovedAttendance,
  downloadAttendance,
  getAttendanceLogs,
  getAttendanceOverview,
  getReviewAttendance,
} from "../../Redux/AppReducer/Action";
import { Doughnut } from "react-chartjs-2";
import { FiDownload } from "react-icons/fi";
import { DownloadIcon } from "@chakra-ui/icons";

const MyAttendancePage = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [selectedAttendanceIds, setSelectedAttendanceIds] = useState([]);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { colorMode } = useColorMode();
  const attendanceOverviewData = useSelector(
    (state) => state.app.ATTENDANCEOVERVIEWs
  );
  const attendanceLogsData = useSelector((state) => state.app.ATTENDANCELOGSs);
  const getReview = useSelector((state) => state.app.GETREVIEWATTENDANCEs);

  useEffect(() => {
    dispatch(getAttendanceOverview(token));
    dispatch(getAttendanceLogs(token));
    dispatch(getReviewAttendance(token));
  }, [dispatch, token]);

  useEffect(() => {
    setFilteredData(attendanceLogsData?.attendanceRecords);
  }, [attendanceLogsData]);

  const handleSearch = () => {
    dispatch(getAttendanceLogs(token, employeeID, fromDate, toDate)).then(
      (action) => {
        if (action?.payload && action?.payload.attendanceRecords) {
          setFilteredData(action?.payload.attendanceRecords);
        }
      }
    );
  };

  const handleReview = () => {
    dispatch(getReviewAttendance(token, employeeID, fromDate, toDate));
    setIsReviewMode(true);
  };

  const handleApprove = () => {
    dispatch(ApprovedAttendance(token, selectedAttendanceIds));
    console.log("Approved");
    handleReview();
  };

  const handleDownload = () => {
    dispatch(downloadAttendance(token, employeeID, fromDate, toDate));
  };

  const handleCheckboxChange = (index) => {
    setSelectedAttendanceIds((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((id) => id !== index)
        : [...prevSelected, index]
    );
  };

  const createChartData = (label, value, total, color) => ({
    labels: [label, "Remaining"],
    datasets: [
      {
        label: label,
        data: [value, total - value],
        backgroundColor: [color, "#c7bebd"],
        hoverBackgroundColor: [color, "#c7bebd"],
      },
    ],
  });

  return (
    <Box
      p={"8px"}
      mt={"70px"}
      ml={"280px"}
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
    >
      <Box
        p={6}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        borderRadius="md"
        mb={6}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <Heading
          fontSize={20}
          mb={"30px"}
          color={colorMode === "dark" ? "white" : "#000000"}
          textAlign="left"
        >
          Days Overview This Month
        </Heading>
        <Flex justify="space-between" h={"250px"}>
          <Box>
            <Text fontWeight="bold">Total Working Days:</Text>
            <Doughnut
              height={"180px"}
              width={"180px"}
              data={createChartData(
                "Total Working Days",
                attendanceOverviewData?.totalWorkingDays,
                30,
                "#F56565"
              )}
            />
            <Text>{attendanceOverviewData?.totalWorkingDays} Days</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Total Present Employees:</Text>
            <Doughnut
              height={"180px"}
              width={"180px"}
              data={createChartData(
                "Total Present Employees",
                attendanceOverviewData?.totalPresentToday,
                100,
                "#D9D22E"
              )}
            />
            <Text>{attendanceOverviewData?.totalPresentToday} Present</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Total Absent Employees:</Text>
            <Doughnut
              height={"180px"}
              width={"180px"}
              data={createChartData(
                "Total Absent Employees",
                attendanceOverviewData?.totalAbsentToday,
                100,
                "#fa9e34"
              )}
            />
            <Text>{attendanceOverviewData?.totalAbsentToday} Absent</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Holidays:</Text>
            <Doughnut
              height={"180px"}
              width={"180px"}
              data={createChartData(
                "Holidays",
                attendanceOverviewData?.totalHolidays,
                10,
                "#86B6F6"
              )}
            />
            <Text>{attendanceOverviewData?.totalHolidays} Holidays</Text>
          </Box>
        </Flex>
      </Box>

      <Box
        p={6}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        borderRadius="md"
        mb={6}
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <Heading
          fontSize={20}
          textAlign={"left"}
          color={colorMode === "dark" ? "white" : "#000000"}
        >
          Attendance Overview
        </Heading>
        <Flex p={4} bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}>
          <Box>
            <FormControl>
              <FormLabel>Employee ID</FormLabel>
              <Input
                type="text"
                placeholder="employeeID"
                value={employeeID}
                borderColor="red.500"
                onChange={(e) => setEmployeeID(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box ml={4}>
            <FormControl>
              <FormLabel>From Date</FormLabel>
              <Input
                type="date"
                name="fromDate"
                borderColor="red.500"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box ml={4}>
            <FormControl>
              <FormLabel>To Date</FormLabel>
              <Input
                type="date"
                name="toDate"
                borderColor="red.500"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FormControl>
          </Box>

          <Button
            background={"#e04141"}
            color={"#FECE01"}
            ml={4}
            marginTop={"30px"}
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            background={"#e04141"}
            color={"#FECE01"}
            ml={4}
            marginTop={"30px"}
            onClick={handleReview}
          >
            Review
          </Button>
          <Button
            ml={4}
            background={"#e04141"}
            color={"#FECE01"}
            marginTop={"30px"}
          >
          <IconButton
          color={colorMode === "dark" ? "white" : "black"}
                  icon={<DownloadIcon />}
                  onClick={handleDownload}
                  aria-label="Download"
                />
                </Button>
        </Flex>

        <Heading
          fontSize={20}
          textAlign={"left"}
          color={colorMode === "dark" ? "white" : "#000000"}
        >
          Attendance Logs
        </Heading>

        <Table mt={5} borderWidth="3px" borderColor="#e04141">
          <Thead>
            <Tr>
              {isReviewMode && (
                <Th
                  border="2px solid #e04141"
                  color={"#e04141"}
                  fontSize={14}
                ></Th>
              )}
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Employee ID
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Employee Name
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Status
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Check-In
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Check-Out
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Working Hours
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map((item, index) => (
              <Tr key={item.employeeID}>
                {isReviewMode && (
                  <Td border="2px solid #e04141">
                    <Checkbox
                      border={"grey"}
                      isChecked={selectedAttendanceIds.includes(item._id)}
                      onChange={() => handleCheckboxChange(item._id)}
                    />
                  </Td>
                )}
                <Td border="2px solid #e04141">{item.employeeID}</Td>
                <Td border="2px solid #e04141">{item.employeeName}</Td>
                <Td border="2px solid #e04141">{item.status}</Td>
                <Td border="2px solid #e04141">{item.checkIn}</Td>
                <Td border="2px solid #e04141">{item.checkOut}</Td>
                <Td border="2px solid #e04141">{item.workingHours}</Td>
              </Tr>
            ))}
            {filteredData?.length === 0 && (
              <Tr>
                <Td colSpan="6" textAlign="center">
                  No data available.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        {isReviewMode && (
          <Flex justify="flex-end" width="100%" mt={"10px"}>
            <Button colorScheme="green" onClick={handleApprove}>
              Approve
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default MyAttendancePage;
