import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Alert,
  AlertIcon,
  HStack,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  useColorMode,
  Link,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserComplaint, postComplaint } from "../../Redux/AppReducer/Action";

const ComplaintForm = () => {
  const user = useSelector((store) => store.auth.user);
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const userEmployeeName = useSelector(
    (state) => state.auth.user?.employeeName
  );

  const getalldata = useSelector((state) => state.app.GETUSERCOMPLAINTs);
  console.log(getalldata);

  useEffect(() => {
    dispatch(GetUserComplaint(token));
  }, [dispatch, token]);

  const [complaintDetails, setComplaintDetails] = useState({
    employeeName: "",
    subject: "",
    description: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedComplaints, setSubmittedComplaints] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setComplaintDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postComplaint(token, complaintDetails));
    setIsSubmitted(true);
    setSubmittedComplaints((prevComplaints) => [
      ...prevComplaints,
      complaintDetails,
    ]);
    dispatch(GetUserComplaint(token));
    setComplaintDetails({
      employeeName: "",
      subject: "",
      description: "",
    });
  };

  return (
    <Box
      mt={"70px"}
      ml={"280px"}
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
      pt={"20px"}
    >
      <Flex align="center" justify="center">
        <Box
          maxW="1000px"
          maxH={"auto"}
          p={10}
          background={colorMode === "dark" ? "dark" : "#fae8e1"}
          border="2px solid #e04141"
          borderRadius="10px"
          boxShadow={
            colorMode === "dark"
              ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
              : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
          }
        >
          <Heading
            mb="4"
            textAlign="left"
            color={colorMode === "dark" ? "white" : "black"}
            fontSize={25}
          >
            Raise a Complaint
          </Heading>
          <form onSubmit={handleSubmit}>
            <HStack spacing={10} w="100%">
              <FormControl>
                <FormLabel htmlFor="employeeName">Employee Name:</FormLabel>
                <Select
                  id="employeeName"
                  name="employeeName"
                  borderColor={"#e04141"}
                  placeholder="Employee Name"
                  value={complaintDetails.employeeName}
                  onChange={handleInputChange}
                  required
                >
                  <option value={userEmployeeName}>{userEmployeeName}</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="subject">Subject:</FormLabel>
                <Input
                  type="text"
                  id="subject"
                  name="subject"
                  borderColor={"#e04141"}
                  value={complaintDetails.subject}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="description">Description:</FormLabel>
                <Input
                  id="description"
                  name="description"
                  borderColor={"#e04141"}
                  value={complaintDetails.description}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <Button
                type="submit"
                background={"#e04141"}
                color={"#FECE01"}
                w={"70%"}
                mt={"25px"}
              >
                Submit
              </Button>
            </HStack>
          </form>
          <Divider
            mt={"20px"}
            pb={3}
            borderBottom="4px solid rgba(0, 0, 0, .08)"
          />

          <Box mt={10}>
            <Heading
              mb={4}
              textAlign={"left"}
              color={colorMode === "dark" ? "white" : "black"}
              fontSize={20}
            >
              Submitted Complaints
            </Heading>
            <Table>
              <Thead>
                <Tr>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Employee Name
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Subject
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Description
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {getalldata?.map((complaint, index) => (
                  <Tr key={index}>
                    <Td border="2px solid #e04141">{complaint.employeeName}</Td>
                    <Td border="2px solid #e04141">{complaint.subject}</Td>
                    <Td border="2px solid #e04141">{complaint.description}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ComplaintForm;
