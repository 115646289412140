import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Heading,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReferral } from "../../Redux/AppReducer/Action";

const ReferralRequest = () => {
  // const [requests, setRequests] = useState([]);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getReferral = useSelector((state) => state.app.GETALLREFERRALs);

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (token) {
      dispatch(getAllReferral(token));
    }
  }, [dispatch, token]);

 

  return (
    <Box mt="70px" ml="280px" background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}>
      <Heading
        fontSize={25}
        mb={"20px"}
        ml={4}
        pt={4}
        color={colorMode === "dark" ? "white" : "#000000"}
        textAlign="left"
      >
        Referral Requests
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow={colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <TableContainer>
          <Table borderWidth="3px" borderColor="#e04141">
            <Thead>
              <Tr>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Referral ID
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Referred By
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Applicant Name
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Email ID
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Contact Number
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Applied Position
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Applied Date
                </Th>
                
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={"14"}>
                  Resume
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {getReferral?.map((request) => (
                <Tr key={request.id} borderColor="black">
                  <Td border="2px solid #e04141">{request._id}</Td>
                  <Td border="2px solid #e04141">{request.referredBy}</Td>
                  <Td border="2px solid #e04141">{request.name}</Td>
                  <Td border="2px solid #e04141">{request.email}</Td>
                  <Td border="2px solid #e04141">{request.contactNumber}</Td>
                  <Td border="2px solid #e04141">{request.position}</Td>
                  <Td border="2px solid #e04141">{request.appliedDate}</Td>
                  <Td border="2px solid #e04141">
                    <Link href={request.resume} isExternal>
                      Download Resume
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ReferralRequest;
