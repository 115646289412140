import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  Heading,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAllHikes } from "../../Redux/AppReducer/Action";
import { color } from "framer-motion";

const AppraisalRequest = () => {
  const { colorMode } = useColorMode();
  // const toast = useToast();

  const [requests, setRequests] = useState([
    {
      id: 1,
      employeeID: "EMP652",
      employeeName: "Prakruthi",
      department: "Dev",
      requestedDate: "23/05/2024",
      expectedHikePercentage: "20%",
      action: "",
      color: "black",
    },
  ]);

  const token = useSelector((store) => store.auth.token);
  const AdminHike = useSelector((store) => store.app.GETALLHIKESs);
  console.log(AdminHike);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllHikes(token));
  }, [dispatch, token]);

  const handleActionChange = (id, action) => {
    const colorMap = {
      Approved: "green",
      Pending: "orange",
      Rejected: "red",
    };
    const color = colorMap[action] || "black";

    const updatedRequests = requests.map((request) =>
      request.id === id ? { ...request, action, color } : request
    );

    setRequests(updatedRequests);

    console.log("Appraisal Request for Employee ID", id, "submitted:", action);

    // toast({
    //   title: "Appraisal Request",
    //   description: `Request for Employee ID ${id} ${action.toLowerCase()} successfully!`,
    //   status: "success",
    //   duration: 5000,
    //   isClosable: true,
    // });
  };

     
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };


  return (
    <Box mt="70px" ml="280px" color="black" background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}>
      <Heading
        fontSize={25}
        mb={"20px"}
        ml={4}
        pt={4}
        color={colorMode === "dark" ? "white" : "#000000"}
        textAlign="left"
      >
        Appraisal Requests
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <TableContainer>
          <Table borderWidth="3px" borderColor="#e04141">
            <Thead>
              <Tr>
                <Th border="2px solid #e04141" color="#e04141" fontSize={14}>Employee ID</Th>
                <Th border="2px solid #e04141" color="#e04141" fontSize={14}>Employee Name</Th>
                <Th border="2px solid #e04141" color="#e04141" fontSize={14}>Department</Th>
                <Th border="2px solid #e04141" color="#e04141" fontSize={14}>Requested Date</Th>
                <Th border="2px solid #e04141" color="#e04141" fontSize={14}>Expected Hike %</Th>
              </Tr>
            </Thead>
            <Tbody>
              {AdminHike?.length > 0 &&
                AdminHike?.map((request) => (
                  <Tr key={request.id} borderColor="black">
                    <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #e04141">{request.employeeID}</Td>
                    <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #e04141">{request.employeeName}</Td>
                    <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #e04141">{request.department}</Td>
                    <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #e04141">{formatDate(request.requestedDate)}</Td>
                    <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #e04141">{request.expectedHikePercentage}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AppraisalRequest;
