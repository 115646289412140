import * as types from "./ActionTypes";
const initialState = {
  DASHBOARD_DETAILSs: {},
  ALLEMPLOYEEs: [],
  ALLATTENDANCEs: {},
  ATTENDANCEOVERVIEWs: {},
  ATTENDANCELOGSs: [],
  GETREVIEWATTENDANCEs: [],
  APPROVEDATTENDANCEs: [],
  DOWNLOADATTENDANCEs: [],
  REGISTERs: [],
  SEARCHEMPLOYEEs: [],
  UPDATEEMPLOYEEs: [],
  CHECKINEMPLOYEEs: [],
  CHECKOUTEMPLOYEEs: [],
  CREATELEAVEs: [],
  GETUSERLEAVEs:[],
  GETSTATUSs: [],
  GETLEAVEREQUESTs: [],
  UPDATESTATUSs: [],
  UPCOMINGEVENTs: [],
  CREATEEVENTSs: [],
  DELETEEMPLOYEEs: [],
  CREATEHIKEs: [],
  GETALLHIKESs: [],
  GETUSERHIKEs: [],
  RESIGNs: [],
  GETLEAVEDETAILSs: {},
  CREATEREWARDSs: [],
  GETAWARDSANDREWARDSs: [],
  GETREWARDs: [],
  POSTCOMPLAINTs: [],
  GETCOMPLAINTs: [],
  GETUSERCOMPLAINTs: [],
  GETSTATISTICSs: [],
  GETUPDATESTATUSs: [],
  GETTICKETSs: [],
  GETALLTICKETSS: [],
  GETUSERTICKETS: [],
  CREATETICKETSs: [],
  EMPLOYEESETUP2FAs: {},
  EMPVARIFY2FAs: [],
  GETEMPINFOs: {},
  POSTUPLOADDOCUMENTs: [],
  USERCREATEREFERRALs: [],
  GETALLREFERRALs: [],
  USERREIMBURSEMENTs: [],
  GETALLREIMBURSEMENTs: [],
  APPROVEDREIMBURSEMENTs: [],
  GETPAYSLIPs: {},
  isLoading: false,
  isError: false,
};

//Dashboard Details

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.DASHBOARD_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.DASHBOARD_DETAILS_SUCCESS: {
      return {
        ...state,
        DASHBOARD_DETAILSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.DASHBOARD_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        DASHBOARD_DETAILSs: [],
        isError: true,
      };
    }

    //All Emlployee details

    case types.ALLEMPLOYEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ALLEMPLOYEE_SUCCESS: {
      return {
        ...state,
        ALLEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ALLEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ALLEMPLOYEEs: [],
        isError: true,
      };
    }

    //All employee attendance

    case types.ALLATTENDANCE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ALLATTENDANCE_SUCCESS: {
      return {
        ...state,
        ALLATTENDANCEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ALLATTENDANCE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ALLATTENDANCEs: [],
        isError: true,
      };
    }

    //Employee Attendance (Admin)

    case types.ATTENDANCEOVERVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ATTENDANCEOVERVIEW_SUCCESS: {
      return {
        ...state,
        ATTENDANCEOVERVIEWs: payload,
        isLoading: true,
        isError: false,
      };
    }
    case types.ATTENDANCEOVERVIEW_FAILURE: {
      return {
        ...state,
        isLoading: true,
        ATTENDANCEOVERVIEWs: [],
        isError: false,
      };
    }

    //Attendance Details(Admin)

    case types.ATTENDANCELOGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        ATTENDANCELOGSs: [],
      };
    }
    case types.ATTENDANCELOGS_SUCCESS: {
      return {
        ...state,
        ATTENDANCELOGSs: payload,
        isLoading: true,
        isError: false,
      };
    }
    case types.ATTENDANCELOGS_FAILURE: {
      return {
        ...state,
        isLoading: true,
        ATTENDANCELOGSs: [],
        isError: false,
      };
    }

    // Review Attendance (Admin)

    case types.GETREVIEWATTENDANCE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        ATTENDANCELOGSs: [],
      };
    }
    case types.GETREVIEWATTENDANCE_SUCCESS: {
      return {
        ...state,
        ATTENDANCELOGSs: payload,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETREVIEWATTENDANCE_FAILURE: {
      return {
        ...state,
        isLoading: true,
        GETREVIEWATTENDANCEs: [],
        isError: false,
      };
    }

    // Approved Attendance (Admin)

    case types.APPROVEDATTENDANCE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.APPROVEDATTENDANCE_SUCCESS: {
      return {
        ...state,
        APPROVEDATTENDANCEs: payload,
        isLoading: true,
        isError: false,
      };
    }
    case types.APPROVEDATTENDANCE_FAILURE: {
      return {
        ...state,
        isLoading: true,
        APPROVEDATTENDANCEs: [],
        isError: false,
      };
    }

//Download Attendance(Admin)

case types.DOWNLOADATTENDANCE_REQUEST: {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
}
case types.DOWNLOADATTENDANCE_SUCCESS: {
  return {
    ...state,
    DOWNLOADATTENDANCEs: payload,
    isLoading: true,
    isError: false,
  };
}
case types.DOWNLOADATTENDANCE_FAILURE: {
  return {
    ...state,
    isLoading: true,
    DOWNLOADATTENDANCEs: [],
    isError: false,
  };
}


    // Add new Employee

    case types.REGISTER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.REGISTER_SUCCESS: {
      return {
        ...state,
        REGISTERs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.REGISTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        REGISTERs: [],
        isError: true,
      };
    }

    // Search employee details

    case types.SEARCHEMPLOYEE_REQUEST: {
      return {
        ...state,
        ALLEMPLOYEEs: [],
        isLoading: true,
        isError: false,
      };
    }
    case types.SEARCHEMPLOYEE_SUCCESS: {
      return {
        ...state,
        ALLEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.SEARCHEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        SEARCHEMPLOYEEs: [],
        isError: true,
      };
    }

    //Update employee data

    case types.UPDATEEMPLOYEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.UPDATEEMPLOYEE_SUCCESS: {
      return {
        ...state,
        UPDATEEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.UPDATEEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        UPDATEEMPLOYEEs: [],
        isError: true,
      };
    }

    //Delete Employee data

    case types.DELETEEMPLOYEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.DELETEEMPLOYEE_SUCCESS: {
      return {
        ...state,
        DELETEEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.DELETEEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        DELETEEMPLOYEEs: [],
        isError: true,
      };
    }

    //Employee CheckIn

    case types.CHECKINEMPLOYEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CHECKINEMPLOYEE_SUCCESS: {
      return {
        ...state,
        CHECKINEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CHECKINEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CHECKINEMPLOYEEs: [],
        isError: true,
      };
    }

    // Employee CheckOut

    case types.CHECKOUTEMPLOYEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CHECKOUTEMPLOYEE_SUCCESS: {
      return {
        ...state,
        CHECKOUTEMPLOYEEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CHECKOUTEMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CHECKOUTEMPLOYEEs: [],
        isError: true,
      };
    }

    // Apply Leave(User)

    case types.CREATELEAVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATELEAVE_SUCCESS: {
      return {
        ...state,
        CREATELEAVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATELEAVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATELEAVEs: [],
        isError: true,
      };
    }

    
    //get All Leave(User)

    case types.GETUSERLEAVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSERLEAVE_SUCCESS: {
      return {
        ...state,
        GETUSERLEAVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSERLEAVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERLEAVEs: [],
        isError: true,
      };
    }





    //Leave Staus Search(User)

    case types.GETSTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETSTATUS_SUCCESS: {
      return {
        ...state,
        GETSTATUSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETSTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETSTATUSs: [],
        isError: true,
      };
    }

    // Get Leave Request(Admin)

    case types.GETLEAVEREQUEST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETLEAVEREQUEST_SUCCESS: {
      return {
        ...state,
        GETLEAVEREQUESTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETLEAVEREQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETLEAVEREQUESTs: [],
        isError: true,
      };
    }

    //Update Leave Staus(Admin)

    case types.UPDATESTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.UPDATESTATUS_SUCCESS: {
      return {
        ...state,
        UPDATESTATUSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.UPDATESTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        UPDATESTATUSs: [],
        isError: true,
      };
    }

    //Upcoming Events(Admin/User)

    case types.UPCOMINGEVENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.UPCOMINGEVENT_SUCCESS: {
      return {
        ...state,
        UPCOMINGEVENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.UPCOMINGEVENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        UPCOMINGEVENTs: [],
        isError: true,
      };
    }

    //Create Event(Admin)

    case types.CREATEEVENTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATEEVENTS_SUCCESS: {
      return {
        ...state,
        CREATEEVENTSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATEEVENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATEEVENTSs: [],
        isError: true,
      };
    }

    //Employee Hike(Admin)

    case types.GETALLHIKES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLHIKES_SUCCESS: {
      return {
        ...state,
        GETALLHIKESs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLHIKES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLHIKESs: [],
        isError: true,
      };
    }

    //Create employee Hike(User)

    case types.CREATEHIKE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATEHIKE_SUCCESS: {
      return {
        ...state,
        GCREATEHIKEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATEHIKE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATEHIKEs: [],
        isError: true,
      };
    }

    // Get employee Hike(User)

    case types.GETUSERHIKE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSERHIKE_SUCCESS: {
      return {
        ...state,
        GETUSERHIKEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSERHIKE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERHIKEs: [],
        isError: true,
      };
    }

    // resign

    case types.RESIGN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.RESIGN_SUCCESS: {
      return {
        ...state,
        RESIGNs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.RESIGN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        RESIGNs: [],
        isError: true,
      };
    }

    // Leave Details for user Dashboard

    case types.GETLEAVEDETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETLEAVEDETAILS_SUCCESS: {
      return {
        ...state,
        GETLEAVEDETAILSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETLEAVEDETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETLEAVEDETAILSs: [],
        isError: true,
      };
    }

    //Awards & Rewards (Admin)

    case types.CREATEREWARDS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATEREWARDS_SUCCESS: {
      return {
        ...state,
        CREATEREWARDSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATEREWARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATEREWARDSs: [],
        isError: true,
      };
    }

    //Awards $ Rewards (Admin)

    case types.GETAWARDSANDREWARDS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETAWARDSANDREWARDS_SUCCESS: {
      return {
        ...state,
        GETAWARDSANDREWARDSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETAWARDSANDREWARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETAWARDSANDREWARDSs: [],
        isError: true,
      };
    }

    // Awards & Rewards(User)

    case types.GETREWARD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETREWARD_SUCCESS: {
      return {
        ...state,
        GETREWARDs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETREWARD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETREWARDs: [],
        isError: true,
      };
    }

    // Rise A Complaint(USer)

    case types.POSTCOMPLAINT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.POSTCOMPLAINT_SUCCESS: {
      return {
        ...state,
        POSTCOMPLAINTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.POSTCOMPLAINT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        POSTCOMPLAINTs: [],
        isError: true,
      };
    }

    //Get All Comaplaint (User)

    case types.GETUSERCOMPLAINT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSERCOMPLAINT_SUCCESS: {
      return {
        ...state,
        GETUSERCOMPLAINTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSERCOMPLAINT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERCOMPLAINTs: [],
        isError: true,
      };
    }

    // GetComplaint (Admin)

    case types.GETCOMPLAINT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETCOMPLAINT_SUCCESS: {
      return {
        ...state,
        GETCOMPLAINTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETCOMPLAINT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETCOMPLAINTs: [],
        isError: true,
      };
    }

    // Tickets Statistics(Admin)

    case types.GETSTATISTICS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETSTATISTICS_SUCCESS: {
      return {
        ...state,
        GETSTATISTICSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETSTATISTICS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETSTATISTICSs: [],
        isError: true,
      };
    }

    // Update Status(Admin)

    case types.GETUPDATESTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUPDATESTATUS_SUCCESS: {
      return {
        ...state,
        GETUPDATESTATUSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUPDATESTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUPDATESTATUSs: [],
        isError: true,
      };
    }

    // Get All Tickets(Admin)

    case types.GETTICKETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETTICKETS_SUCCESS: {
      return {
        ...state,
        GETTICKETSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETTICKETS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETTICKETSs: [],
        isError: true,
      };
    }

    //Get user Tickets (User)

    case types.GETUSERTICKETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETUSERTICKETS_SUCCESS: {
      return {
        ...state,
        GETUSERTICKETS: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETUSERTICKETS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETUSERTICKETS: [],
        isError: true,
      };
    }

    //Get All Tickets (User)

    case types.GETALLTICKETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLTICKETS_SUCCESS: {
      return {
        ...state,
        GETALLTICKETSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLTICKETS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLTICKETSs: [],
        isError: true,
      };
    }

    // Create Tickets(User)

    case types.CREATETICKETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.CREATETICKETS_SUCCESS: {
      return {
        ...state,
        CREATETICKETSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATETICKETS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        CREATETICKETSs: [],
        isError: true,
      };
    }

    // 2FA verification setup(User employeePage)

    case types.EMPLOYEESETUP2FA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EMPLOYEESETUP2FA_SUCCESS: {
      return {
        ...state,
        EMPLOYEESETUP2FAs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.EMPLOYEESETUP2FA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        EMPLOYEESETUP2FAs: [],
        isError: true,
      };
    }

    // Employee Profile Page (User)

    case types.GETEMPINFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETEMPINFO_SUCCESS: {
      return {
        ...state,
        GETEMPINFOs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETEMPINFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETEMPINFOs: [],
        isError: true,
      };
    }

    // Upload Documents (Admin)

    case types.POSTUPLOADDOCUMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.POSTUPLOADDOCUMENT_SUCCESS: {
      return {
        ...state,
        POSTUPLOADDOCUMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.POSTUPLOADDOCUMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        POSTUPLOADDOCUMENTs: [],
        isError: true,
      };
    }


    //Create Referral (User)

    case types.USERCREATEREFERRAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.USERCREATEREFERRAL_SUCCESS: {
      return {
        ...state,
        USERCREATEREFERRALs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.USERCREATEREFERRAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        USERCREATEREFERRALs: [],
        isError: true,
      };
    }

    //get All Referral(Admin)

    case types.GETALLREFERRAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLREFERRAL_SUCCESS: {
      return {
        ...state,
        GETALLREFERRALs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLREFERRAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLREFERRALs: [],
        isError: true,
      };
    }


    //Post Reimbursement(User)

    case types.USERREIMBURSEMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.USERREIMBURSEMENT_SUCCESS: {
      return {
        ...state,
        USERREIMBURSEMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.USERREIMBURSEMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        USERREIMBURSEMENTs: [],
        isError: true,
      };
    }
    
    // Get Reimbursement(Admin)

     case types.GETALLREIMBURSEMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLREIMBURSEMENT_SUCCESS: {
      return {
        ...state,
        GETALLREIMBURSEMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLREIMBURSEMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLREIMBURSEMENTs: [],
        isError: true,
      };
    }

    //Approved Reimbursement(Admin)

    case types.APPROVEDREIMBURSEMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }

    case types.APPROVEDREIMBURSEMENT_SUCCESS: {
      return {
        ...state,
        APPROVEDREIMBURSEMENTs: payload,
        isLoading: false,
        isError: false,
      };
    }

    case types.APPROVEDREIMBURSEMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        APPROVEDREIMBURSEMENTs: [],
        isError: true,
      };
    }

    // Handle the update of reimbursement status (for both approval and rejection)
    case 'UPDATE_REIMBURSEMENT_STATUS': {
      return {
        ...state,
        GETALLREIMBURSEMENTs: {
          ...state.GETALLREIMBURSEMENTs,
          claims: payload, // Update the claims array with the new status
        },
      };
    }

    // Optional: Handle the removal of a reimbursement (if you have such action)
    case 'REMOVE_REIMBURSEMENT': {
      return {
        ...state,
        GETALLREIMBURSEMENTs: {
          ...state.GETALLREIMBURSEMENTs,
          claims: payload, // Assuming payload is the filtered claims array
        },
      };
    }



    //get payslip

    case types.GETPAYSLIP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPAYSLIP_SUCCESS: {
      return {
        ...state,
        GETPAYSLIPS: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPAYSLIP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPAYSLIPS: [],
        isError: true,
      };
    }










    default:
      return state;
  }
};
