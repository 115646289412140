import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { UserCreateReferral } from "../../Redux/AppReducer/Action";

const Referral = () => {
  const { colorMode } = useColorMode();
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    position: "",
    email: "",
    referredBy:"",
    resume: null,
  });


  const [emailError, setEmailError] = useState("");
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "applicantName" && !/^[a-zA-Z\s]*$/.test(value)) return;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitApplication = () => {
    const { position, contactNumber, name, email, resume, referredBy } = formData;

    // console.log("Job Title:", jobTitle);
    // console.log("Contact Number:", contactNumber);
    // console.log("Applicant Name:", applicantName);
    // console.log("Applicant Email:", applicantEmail);
    // console.log("referredBy:", referredBy);

    console.log("Resume:", resume ? resume.name : "No file selected");

    if (!position || !contactNumber || !name || !email || !resume || !referredBy) {
      alert("All fields are required.");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    const submissionData = new FormData();
    for (let key in formData) {
      submissionData.append(key, formData[key]);
    }

    dispatch(UserCreateReferral(token, submissionData));

    setFormData({
      position: "",
      contactNumber: "",
      name: "",
      email: "",
      referredBy: "",
      resume: null,
    });

    alert("Application submitted successfully!");
  };

  return (
    <Box
      mt="70px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
      borderWidth="1px"
    >
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        mt={"20px"}
        ml={"250px"}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        width={"50%"}
        pt={"10px"}
        mb={4}
        pl={"17px"}
        border="2px solid #e04141"
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <FormLabel
          fontSize={25}
          mt={1}
          mb={4}
          pl={"125px"}
          position="center"
          color={colorMode === "dark" ? "white" : "black"}
        >
          Applicant Details
        </FormLabel>
        <FormControl mb={2} borderColor="teal.200" _focus={{ borderColor: "teal.500" }}>
          <FormLabel pl={"10px"}>Name</FormLabel>
          <Input
            type="text"
            name="name"
            borderColor={"#e04141"}
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
          />
        </FormControl>

        <FormControl mb={2} borderColor="teal.200" _focus={{ borderColor: "teal.500" }}>
          <FormLabel pl={"10px"}>Email</FormLabel>
          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            borderColor={"#e04141"}
            value={formData.email}
            onChange={handleChange}
            isInvalid={emailError !== ""}
            _placeholder={{ color: "gray.500" }}
          />
          {emailError && (
            <Box color="red.500" fontSize="sm" mt={1}>
              {emailError}
            </Box>
          )}
        </FormControl>

        <FormControl mb={2} borderColor={"#e04141"} _focus={{ borderColor: "teal.500" }} id="resume">
          <FormLabel pl={"10px"}>Resume</FormLabel>
          <Input
            pt={1}
            borderColor={"#e04141"}
            type="file"
            name="resume"
            ref={fileInputRef}
            accept=".pdf"
            onChange={handleFileChange}
            _placeholder={{ color: "gray.500" }}
          />
        </FormControl>

        <FormControl mb={2} borderColor="teal.200" _focus={{ borderColor: "teal.500" }}>
          <FormLabel pl={"10px"}>Position</FormLabel>
          <Input
            type="text"
            name="position"
            placeholder="Enter job title"
            borderColor={"#e04141"}
            value={formData.position}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
          />
        </FormControl>

           <FormControl mb={2} borderColor="teal.200" _focus={{ borderColor: "teal.500" }}>
          <FormLabel pl={"10px"}>ReferredBy</FormLabel>
          <Input
            type="text"
            name="referredBy"
            placeholder="Enter job title"
            borderColor={"#e04141"}
            value={formData.referredBy}
            onChange={handleChange}
            _placeholder={{ color: "gray.500" }}
          />
        </FormControl>

        <FormControl mb={4} borderColor="teal.200" _focus={{ borderColor: "teal.500" }}>
          <FormLabel pl={"10px"}>Contact Number</FormLabel>
          <Input
            placeholder="Enter contact Number"
            name="contactNumber"
            borderColor={"#e04141"}
            value={formData.contactNumber}
            type="number"
            onChange={handleChange}
            rows={2}
            _placeholder={{ color: "gray.500" }}
          />
        </FormControl>

        <Button color={"#FECE01"} background={"#e04141"} onClick={submitApplication} pl={"10px"}>
          Submit Application
        </Button>
      </Box>
    </Box>
  );
};

export default Referral;
