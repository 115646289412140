import React, {useEffect} from "react";
import Axios from "axios";
import './App.css';
import AllRoutes from "./Routes/AllRoutes";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './Redux/AuthReducer/Action';


const INACTIVITY_TIMEOUT = 30 * 60 * 1000;


function App() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() =>{
    let timeout;

    const resetInactivityTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(logout());
        navigate("/login");
      }, INACTIVITY_TIMEOUT);
    };

     // Listen for user activity globally
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("mousedown", resetInactivityTimer);
    window.addEventListener("touchstart", resetInactivityTimer);

    resetInactivityTimer();


    // Cleanup the event listeners on component unmount
    return () =>{
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("mousedown", resetInactivityTimer);
      window.removeEventListener("touchstart", resetInactivityTimer);
    };
  }, [dispatch,navigate])

  return (
    <div className="App">
    
      <AllRoutes/>
    </div>
  );
}

export default App;
