import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import ProfileImage from "../../Components/Images/profile.jpg";
import { SiGooglemarketingplatform } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";

const AboutUs = () => {
  const { colorMode } = useColorMode();

  return (
    <Box
      mt={"70px"}
      p={6}
      height={"auto"}
      position={"relative"}
      ml={"280px"}
      bg={colorMode === "dark" ? "gray.800" : "#fcf7d2"}
      color={colorMode === "dark" ? "white" : "black"}
    >
      <Box>
        <Flex gap={"20px"}>
          <Box width={"60%"}>
            <Text textAlign={"left"} padding={"10px"}>
              About Our Company ~
            </Text>
            <Heading textAlign={"left"} padding={"10px"}>
              Choose{" "}
              <Heading as="span" color="red">
                The Best.
              </Heading>{" "}
              IT <br /> Service Company
            </Heading>
            <Text textAlign={"justify"} padding={"10px"}>
              Webivalue is a globally based, leading result-driven website
              development and design company, with expertise in e-commerce
              solutions, mobile applications, digital marketing, custom CRM
              software, and SEO services. With years of experience and a proven
              track record, Webivalue is your one-stop solution for all your
              website development and designing needs. As a leading global
              digital agency based in India, Webivalue provides comprehensive
              services that cover everything from website development and
              designing to e-commerce solutions, mobile applications, custom CRM
              software, and SEO services.
            </Text>
          </Box>
          <Box width={"40%"}>
            <Image
              width={"100%"}
              height={"300px"}
              mt={"10px"}
              borderBottomEndRadius={"40px"}
              borderTopLeftRadius={"40px"}
              src={ProfileImage}
            />
          </Box>
        </Flex>
      </Box>

      <Box width={"100%"} height={"500px"} background={colorMode === "dark" ? "dark" : "#fcf7d2"}>
        <Flex>
          <Box>
            <Text color={"red"} textAlign={"center"} padding={"10px"}>
              Who We Are?
            </Text>
            <Heading textAlign={"center"} padding={"10px"}>
              Our{" "}
              <Heading as="span" color="red">
                {" "}
                penetration
              </Heading>{" "}
              testing <br /> team uses an industry
            </Heading>
            <Text textAlign={"center"} padding={"10px"}>
              We provide the full spectrum of IT services and consulting for
              various industries.
            </Text>
            <Flex
              gap={"10px"}
              textAlign={"center"}
              alignItems={"center"}
              mt={"20px"}
            >
              <Box
                width={"40%"}
                height={"200px"}
                margin={"auto"}
                padding={"10px"}
                borderRadius={"10px"}
                border="2px solid #e04141"
                boxShadow={
                  "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                _hover={{
                  background: "#D00202",
                  color: "#ffffff",
                  border: "2px solid #FECE01",
                }}
              >
                <Center fontSize={"50px"}>
                  <SiGooglemarketingplatform />
                </Center>
                <Text fontWeight={600} padding={"10px"}>
                  Custom Softwares
                </Text>
                <Text>
                  Our customers get solutions and business opportunities instead
                  of just projects. Our mission is to accelerate.
                </Text>
              </Box>
              <Box
                width={"40%"}
                margin={"auto"}
                height={"200px"}
                padding={"10px"}
                borderRadius={"10px"}
                border="2px solid #e04141"
                boxShadow={
                  "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                _hover={{
                  background: "#D00202",
                  color: "#ffffff",
                  border: "2px solid #FECE01",
                }}
              >
                <Center fontSize={"50px"}>
                  <GrTechnology />
                </Center>
                <Text fontWeight={600} padding={"10px"}>
                  High End Technologies
                </Text>
                <Text>
                  Our developers work on trending technologies to design web and
                  on mobile applications.
                </Text>
              </Box>
            </Flex>
            <Button
              color={"#ffffff"}
              bg={"red"}
              padding={"25px 30px"}
              mt={"30px"}
              fontWeight={"bold"}
              _hover={{
                background: "white",
                color: "black",
              }}
            >
              About Us
            </Button>
          </Box>
        </Flex>

        <Flex gap={"20px"} mt={"30px"} background={colorMode === "dark" ? "dark" : "#fae8e1"}>
          <Box width={"40%"}>
            <Image
              width={"100%"}
              height={"330px"}
              padding={"10px"}
              mt={"10px"}
              borderBottomLeftRadius={"40px"}
              borderTopRightRadius={"40px"}
              src={ProfileImage}
            />
          </Box>

          <Box width={"60%"}>
            <Heading textAlign={"left"} padding={"10px"}>
              Growing Business <br /> Through Technology
            </Heading>
            <Text textAlign={"justify"} padding={"10px"}>
              At Webivalue Digital Solutions, our vision is to become the
              leading provider of digital solutions and web development services
              in the global market. We strive to build innovative and intuitive
              web applications that empower our customers to better serve their
              own users and achieve their business goals. We provide our clients
              with the latest technologies and tools to ensure they get the best
              user experience possible. Through continuous innovation and
              development, we are committed to delivering outstanding results
              and customer satisfaction.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default AboutUs;
