import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAttendance } from "../../../Redux/AppReducer/Action";

const WeeklyAttendance = () => {
  function getWeekDates(date) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - dayOfWeek + 1);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 5);

    const weekDates = [];
    for (let i = 0; i < 6; i++) {
      const currentDay = new Date(startDate);
      currentDay.setDate(startDate.getDate() + i);
      const formattedDate = currentDay.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
      weekDates.push({ date: formattedDate, day: days[currentDay.getDay()] });
    }

    return weekDates;
  }

  const date = new Date();
  const weekDates = getWeekDates(date);

  const token = useSelector((state) => state.auth.token);
  const allAttendance = useSelector((state) => state.app.ALLATTENDANCEs);

  const mergeAttendanceData = (weekDates, allAttendance) => {
    return weekDates.map((weekDay) => {
      const attendanceForDay = allAttendance?.find((attendance) => {
        const attendanceDate = new Date(attendance.date);
        const formattedAttendanceDate = attendanceDate.toLocaleDateString(
          "en-US",
          { month: "short", day: "2-digit" }
        );
        return formattedAttendanceDate === weekDay.date;
      });

      return {
        ...weekDay,
        checkInTime: attendanceForDay ? attendanceForDay.checkInTime : "-",
        checkOutTime: attendanceForDay ? attendanceForDay.checkOutTime : "-",
      };
    });
  };

  const weekAttendanceData = mergeAttendanceData(
    weekDates,
    allAttendance?.attendanceRecords
  );

  const calculateTotalWorkingHours = (checkOut, checkIn) => {
    if (checkIn != undefined && checkOut != undefined) {
      return new Date(checkOut).getTime() - new Date(checkIn).getTime();
    }
    // console.log(checkIn);

    return "";
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    if (isNaN(hours)) {
      return "No Data";
    }

    return `${hours}h ${minutes}m ${seconds}s Hours`;
  };
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAttendance(token));
  }, [dispatch, token]);

  return (
    <Box padding={"10px"}>
      <Flex gap={"30px"} border="2px solid #e04141"
      >
        <Box width={"50%"} >
          <Text
            fontSize={"20px"}
            fontWeight={600}
            textAlign={"left"}
            mt={"20px"}
            p={"20px"}
          >
            Weekly Attendance
          </Text>
        </Box>
        <Box width={"50%"} mt={"20px"} p={"20px"}>
          <Text fontSize={"20px"} fontWeight={600}>
            Today{" "}
            {`<${weekDates[0].date}-${weekDates[weekDates.length - 1].date}>`}
          </Text>
        </Box>
      </Flex>
      <hr />
      <Flex>
        <Box width="20%" textAlign="center" border="2px solid #e04141">
          <Text
            fontSize="20px"
            fontWeight={600}
            textAlign="center"
            p="10px"
            color={"#e04141"}
          >
            Day
          </Text>
          {weekAttendanceData?.map((day, index) => (
            <Text key={index} fontSize="16px" p={"10px"} borderTop="2px solid #e04141">
              {day.day}
            </Text>
          ))}
        </Box>
        <Box width="20%" textAlign="center" borderRight="2px solid #e04141" borderTop="2px solid #e04141" borderBottom="2px solid #e04141" >
          <Text
            fontSize="20px"
            fontWeight={600}
            textAlign="center"
            p="10px"
            color={"#e04141"}
          >
            Date
          </Text>
          {weekAttendanceData?.map((day, index) => (
            <Text key={index} fontSize="16px" p={"10px"} borderTop="2px solid #e04141" >
              {day.date}
            </Text>
          ))}
        </Box>
        <Box width="20%" textAlign="center" borderTop="2px solid #e04141" borderBottom="2px solid #e04141">
          <Text
            fontSize="20px"
            fontWeight={600}
            textAlign="center"
            p="10px"
            color={"#e04141"}
          >
            Check-in
          </Text>
          {weekAttendanceData?.map((day, index) => {
             let AMPM = "A.M."
            if (day.checkInTime != undefined) {
              const checkInTime = day?.checkInTime?.split(" ")[1]?.split(":");
                if(checkInTime?.[0]>12){
                    AMPM="P.M."
                    checkInTime[0]=`${checkInTime[0]-12}`
                }
                if(checkInTime?.[0]<10){
                    checkInTime[0] = `0${checkInTime[0]}`;
                }
            //   let hour = parseInt(checkInTime?.[0], 10);

            //   if (hour > 12) {
            //     hour -= 12;
            //   } else if (hour === 0) {
            //     hour = 12;
            //   }
            //   console.log(checkInTime?.[0]>12);
              return (
                <Text key={index} fontSize="16px" p={"10px"} borderTop="2px solid #e04141">
                  {/* {checkInTime?.join(":")}{" "}
                  {AMPM} */}
                  {checkInTime?.[0]==undefined?"Not CheckIN":`${checkInTime?.join(":")} ${AMPM}`}
                </Text>
              );
            }
          })}
        </Box>
        <Box width="20%" textAlign="center" border="2px solid #e04141">
          <Text
            fontSize="20px"
            fontWeight={600}
            textAlign="center"
            p="10px"
            color={"#e04141"}
          >
            Check-out
          </Text>
          {weekAttendanceData?.map((day, index) => {
            let AMPM ="A.M.";
            if (day.checkOutTime != undefined) {
              const checkOutTime = day?.checkOutTime?.split(" ")[1]?.split(":");
              
                if (checkOutTime?.[0] > 12) {
                    AMPM="P.M."
                  checkOutTime[0] = `${checkOutTime[0] - 12}`;
                }
                if(checkOutTime?.[0]<10){
                    checkOutTime[0] = `0${checkOutTime[0]}`;
                }
              
            //   console.log(checkOutTime?.[0]);
              return (
                <Text key={index} fontSize="16px" p={"10px"} borderTop="2px solid #e04141">
                  {isNaN(checkOutTime?.[0]) || (checkOutTime?.[0])==undefined? "Not Checkout":`${checkOutTime?.join(":")} ${AMPM}`}
                </Text>
              );
            }
          })}
        </Box>
        <Box width="20%" textAlign="center" borderTop="2px solid #e04141" borderBottom="2px solid #e04141" borderRight="2px solid #e04141">
          <Text
            fontSize="20px"
            fontWeight={600}
            textAlign="center"
            p="10px"
            color={"#e04141"}
          >
            Total Working
          </Text>
          {weekAttendanceData?.map((day, index) => (
            <Text fontSize="16px" p={"10px"} borderTop="2px solid #e04141">
              {formatTime(
                calculateTotalWorkingHours(
                  day.checkOutTime?.replace(" ", "T"),
                  day.checkInTime?.replace(" ", "T")
                ) 
              )}{" "}
            </Text>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default WeeklyAttendance;
