import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";

import { LiaSortSolid } from "react-icons/lia";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { MdOutlineFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { GetPayslip } from "../../Redux/AppReducer/Action";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import logoImg from "../../Components/Images/logo-img.png";
import numberToWords from "number-to-words";

ChartJS.register(ArcElement, Tooltip, Legend);

const PaySlip = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getpayslip = useSelector((state) => state.app.GETPAYSLIPS);
  console.log(getpayslip);

  useEffect(() => {
    if (selectedMonth) {
      dispatch(GetPayslip(token, selectedMonth, selectedYear));
    }
  }, [selectedMonth, selectedYear, token, dispatch]);

  const data = {
    labels: ["Earnings", "Deductions"],
    datasets: [
      {
        data: [
          getpayslip?.payslip?.grossEarnings,
          getpayslip?.payslip?.totalDeduction,
        ],

        backgroundColor: ["#4caf50", "#f44336"],
        hoverBackgroundColor: ["#66bb6a", "#e57373"],
      },
    ],
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const downloadPayslip = () => {
    if (!selectedMonth && !selectedYear) {
      alert("Please select a month and year");
      return;
    }
    const payslipElement = document.getElementById("payslip-container");
    toPng(payslipElement, { quality: "0.95" })
      .then((dataUrl) => {
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [210, 210], // A4 size
        });

        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("payslip.pdf");
      })
      .catch((error) => {
        console.error("Failed to download the payslip:", error);
      });
  };

  const netPayInWords = numberToWords
    .toWords(getpayslip?.payslip?.netPay || 0)
    .replace(/\b(\w)/g, (char) => char.toUpperCase());

  return (
    <Box>
      <Box
        borderWidth="1px"
        borderRadius="10px"
        maxW="auto"
        mt={120}
        ml={350}
        mr={10}
        mb={10}
        p={4}
      >
        <Heading as="h3" size="lg" mb={7}>
          Download Payslip
        </Heading>
        <HStack spacing={4} mb={6}>
          <LiaSortSolid />
          <Text fontSize="lg" fontWeight="bold">
            Month
          </Text>
          <Select
            placeholder="Select month"
            width="150px"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="January">January</option>
            <option value="February">February </option>
            <option value="March">March </option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </Select>

          <Text fontSize="lg" fontWeight="bold">
            Year
          </Text>
          <Select
            placeholder="Select year"
            width="150px"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
          </Select>
        </HStack>

        <HStack spacing={10} justifyContent="left">
          <Box width="200px">
            <Doughnut data={data} />
          </Box>

          <VStack align="start">
            <HStack>
              <VStack>
                <Text fontSize="lg">Take Home</Text>

                <Text fontSize="2xl" fontWeight="bold" mb={8}>
                  {getpayslip?.payslip?.netPay}
                </Text>
              </VStack>

              <HStack justifyContent="right" ml={350}>
                <MdOutlineFileDownload />

                <Link onClick={downloadPayslip} mr={8}>
                  Download
                </Link>
              </HStack>
            </HStack>

            <HStack spacing={10} justifyContent="left">
              <HStack>
                <VStack borderLeft={"3px solid red"} pl={3} mr={8}>
                  <Text fontSize="lg">Deductions</Text>

                  <Text fontSize="2xl" fontWeight="bold">
                    {getpayslip?.payslip?.totalDeduction}
                  </Text>
                </VStack>

                <VStack borderLeft={"3px solid Green"} pl={3}>
                  <Text fontSize="lg">Gross Pay</Text>

                  <Text fontSize="2xl" fontWeight="bold">
                    {getpayslip?.payslip?.grossEarnings}
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </VStack>
        </HStack>
        {getpayslip?.payslip && (
          <Box
            id="payslip-container"
            mt={"40px"}
            boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
            p={"20px"}
          >
            <Box borderBottom={" 2px solid grey"} mb={8}>
              <Image src={logoImg} ml={-4} width={"auto"} height={"30px"} />

              <Text textAlign={"left"} fontWeight={"bold"}>
                Blipsnip Technologies
              </Text>
              <Text textAlign={"left"} mb={4}>
                #4025-4026, 6th cross road, KR Rd, opp. UCO Bank, Jayanagar 7th
                west Block, Bengaluru, Karnataka 560082
              </Text>
            </Box>

            <Box mt={5}>
              <Heading
                textAlign={"left"}
                fontSize={"xl"}
                mb={6}
                color={"#ed1918"}
              >
                Payslip for the month of {selectedMonth || "______"}{" "}
                {selectedYear || "______"}
              </Heading>

              <Text
                textAlign={"left"}
                fontWeight={"bold"}
                mb={4}
                color={"#ffc102"}
              >
                Employee pay summary
              </Text>

              <Box w={"100%"}>
                <Flex>
                  <Box w={"100%"}>
                    <VStack align="start" ml={8}>
                      <Flex>
                        <Text textAlign={"left"}>Employee Name :</Text>

                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.employeeName || "N/A"}
                        </Text>
                      </Flex>

                      <Flex>
                        <Text textAlign={"left"}>Employee Code :</Text>

                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.employeeID || "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>Designation :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.position || "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>Date of Joining :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.dateOfJoining
                            ? formatDate(getpayslip?.payslip?.dateOfJoining)
                            : "" || "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>UAN :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.UAN || "N/A"}
                        </Text>
                      </Flex>
                    </VStack>
                  </Box>

                  <Box w={"100%"}>
                    <VStack align="start" ml={20}>
                      <Flex>
                        <Text textAlign={"left"}>Total Working Days :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.totalWorkingDays || "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>PAN :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.PAN || "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>
                          Number of Working Days Attended :
                        </Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.numberOfWorkingDaysAttended ||
                            "N/A"}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>Leaves Taken :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.leaveTaken}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text textAlign={"left"}>ESI Number :</Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"500"}
                          ml={4}
                          color={"#e04141"}
                        >
                          {getpayslip?.payslip?.ESINumber}
                        </Text>
                      </Flex>
                    </VStack>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <Box border={"1px solid grey"} mt={8}>
              <Flex>
                <Box w={"50%"} border={"1px solid grey"}>
                  <Heading fontSize={18} background={"#ed1918"}>
                    Income
                  </Heading>
                  <Flex>
                    <Box w={"50%"}>
                      <Heading
                        fontSize={18}
                        textAlign={"left"}
                        background={"#ffc102"}
                        pl={4}
                      >
                        Particulars
                      </Heading>

                      <br />

                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Basic Salary + DA
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        HRA
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Special Allowance                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Medical Allowance
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Transport Allowance
                      </Text>
                      <br />
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Gross Earnings
                      </Text>
                    </Box>
                    <Box w={"50%"} borderLeft={"1px solid grey"}>
                      <Heading
                        fontSize={18}
                        textAlign={"left"}
                        background={"#ffc102"}
                        pl={4}
                      >
                        Amount
                      </Heading>

                      <br />
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.salary}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.HRA}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.specialallowance}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.medicalallowance}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.transportallowance}
                      </Text>
                      <br />
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        {getpayslip?.payslip?.grossEarnings}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Box w={"50%"} border={"1px solid grey"}>
                  <Heading fontSize={18} background={"#ed1918"}>
                    Deductions
                  </Heading>
                  <Flex>
                    <Box w={"50%"}>
                      <Heading
                        fontSize={18}
                        textAlign={"left"}
                        background={"#ffc102"}
                        pl={4}
                      >
                        Particulars
                      </Heading>
                      <br />
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        PF
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Professional Tax
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        TDS
                      </Text>
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        ESI
                      </Text>
                      <br />

                      <br />
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        Total Deductions
                      </Text>
                    </Box>
                    <Box w={"50%"} borderLeft={"1px solid grey"}>
                      <Heading
                        fontSize={18}
                        textAlign={"left"}
                        background={"#ffc102"}
                        pl={4}
                      >
                        Amount
                      </Heading>
                      <br />
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.PF}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.profesionaltax}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.TDS}
                      </Text>
                      <Text textAlign={"left"} fontWeight={"500"} pl={4}>
                        {getpayslip?.payslip?.ESI}
                      </Text>
                      <br />

                      <br />
                      <Text textAlign={"left"} fontWeight={"bold"} pl={4}>
                        {getpayslip?.payslip?.totalDeduction}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
              <Box>
                <Flex>
                  <Text
                    fontWeight={"bold"}
                    fontSize={18}
                    textAlign={"center"}
                    w={"75%"}
                    background={"#ed1918"}
                  >
                    Net Salary:
                  </Text>

                  <Text
                    fontWeight={"bold"}
                    fontSize={18}
                    textAlign={"center"}
                    w={"25%"}
                    background={"#ffc102"}
                  >
                    {getpayslip?.payslip?.netPay}
                  </Text>
                </Flex>
              </Box>
            </Box>
            <Flex textAlign={"left"} pl={3} backgroundColor={"grey"} mt={8}>
              <Text color={"green"} fontSize={20} fontWeight={"bold"}>
                |{" "}
              </Text>
              <Text pt={1} pl={4}>
                Total Net Payable {getpayslip?.payslip?.netPay} (Indian Rupee{" "}
                {netPayInWords} only)
              </Text>
            </Flex>
            <Text mt={4} color="gray.800" opacity={0.8}>
              This is a computer generated invoice no signature required.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PaySlip;
