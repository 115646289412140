import React, { useState } from 'react'
import { checkInEmployee, getAllAttendance } from '../../Redux/AppReducer/Action';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';




const CheckIn = ({setIsCheckedIn}) => {

    
    const token = useSelector((state)=>state.auth.token)
    const getAttendance = useSelector((state) => state.app.ALLATTENDANCEs);
    const dispatch=useDispatch()

    const handleCheckIn = () => {
        const checkInDateTime = new Date().toISOString();
    
        // setIsCheckedIn(true);
        dispatch(checkInEmployee(token))
        .then(()=>{
          dispatch(getAllAttendance(token))
        }
        )
      }
  return (
    <Button
    onClick={handleCheckIn}
    colorScheme="green"
    mr="10px"
    isDisabled={getAttendance?.checkInTime!=""}
  >
    Check In
  </Button>
  )
}

export default CheckIn