import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Heading,
  IconButton,
  Input,
  Textarea,
  useToast,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2, FiSave } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { createEvents, getUpcomingEvent } from "../../Redux/AppReducer/Action";
import { TiPlus } from "react-icons/ti";

const Events = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const upcomingEvents = useSelector((state) => state.app.UPCOMINGEVENTs);
  const createevents = useSelector((state) => state.auth.CREATEEVENTSs);
  console.log(createevents);

  useEffect(() => {
    if (token) {
      dispatch(getUpcomingEvent(token));
    }
  }, [dispatch, token]);

  const [editId, setEditId] = useState(null);
  const [editedEvent, setEditedEvent] = useState({});
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    date: "",
  });

  const handleEdit = (event) => {
    setEditId(event.id);
    setEditedEvent(event);
  };

  const handleSave = (eventId) => {
    // Dispatch an action to update the event in the Redux store here if needed
    setEditId(null);
    setEditedEvent({});
    toast({
      title: "Event updated.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleDelete = (eventId) => {
    // Dispatch an action to delete the event from the Redux store here if needed
    toast({
      title: "Event deleted.",
      status: "success",
      duration: 2000,
      isClosable: true,
      colorScheme: "red",
    });
  };

  const handleAdd = () => {
    if (!newEvent.title || !newEvent.description || !newEvent.date) {
      toast({
        description: "All fields are required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    dispatch(createEvents(token, newEvent)).then(() => {
      setNewEvent({ title: "", description: "", date: "" });
      onClose();
      toast({
        title: "Event added.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      dispatch(getUpcomingEvent(token));
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box
      p={5}
      mt="70px"
      ml="280px"
      color="black"
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
    >
      <Flex justify="space-between" align="center" mb={5}>
        <Heading
          fontSize={25}
          mb={"20px"}
          ml={4}
          pt={4}
          color={colorMode === "dark" ? "white" : "#000000"}
          textAlign="left"
        >
          Events
        </Heading>
        <Button background="#e04141" mr={6} color={"#FECE01"} onClick={onOpen}>
          <TiPlus />
          Add Event
        </Button>
      </Flex>

      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Title
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Description
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Date
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Location
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {upcomingEvents.map((event) => (
                <Tr key={event.id}>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                    maxW={"200px"}
                    overflowX="auto"
                  >
                    {editId === event.id ? (
                      <Input
                        value={editedEvent.title}
                        onChange={(e) =>
                          setEditedEvent({
                            ...editedEvent,
                            title: e.target.value,
                          })
                        }
                      />
                    ) : (
                      event.title
                    )}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {editId === event.id ? (
                      <Textarea
                        value={editedEvent.description}
                        onChange={(e) =>
                          setEditedEvent({
                            ...editedEvent,
                            description: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <Textarea
                        isReadOnly
                        value={event.description}
                        size="sm"
                      />
                    )}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {editId === event.id ? (
                      <Input
                        type="date"
                        value={editedEvent.date}
                        onChange={(e) =>
                          setEditedEvent({
                            ...editedEvent,
                            date: e.target.value,
                          })
                        }
                      />
                    ) : (
                      formatDate(event.date)
                    )}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {editId === event.id ? (
                      <Textarea
                        value={editedEvent.location}
                        onChange={(e) =>
                          setEditedEvent({
                            ...editedEvent,
                            location: e.target.value,
                          })
                        }
                      />
                    ) : (
                      event.location
                    )}
                  </Td>
                  <Td border="2px solid #e04141">
                    {editId === event.id ? (
                      <IconButton
                        icon={<Box as={FiSave} color="green.500" />}
                        aria-label="Save"
                        onClick={() => handleSave(event.id)}
                        mr={2}
                      />
                    ) : (
                      <IconButton
                        icon={<Box as={FiEdit2} color="green.500" />}
                        aria-label="Edit"
                        onClick={() => handleEdit(event)}
                        mr={2}
                      />
                    )}
                    <IconButton
                      icon={<Box as={FiTrash2} color="red.500" />}
                      aria-label="Delete"
                      onClick={() => handleDelete(event.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt={"150px"}>
          <ModalHeader>Add New Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                value={newEvent.title}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, title: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={newEvent.description}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, description: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={newEvent.date}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, date: e.target.value })
                }
              />
              
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Location</FormLabel>
              <Input
                value={newEvent.location}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, location: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAdd}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Events;
