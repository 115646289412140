import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  useToast,
  Text,
  Flex,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import { FiDownload, FiMinusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  ApprovedReimbursement,
  getAllReimbursement,
} from "../../Redux/AppReducer/Action";
import { DownloadIcon } from "@chakra-ui/icons";

const ReimbursementRequest = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [disabledActions, setDisabledActions] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const AllReimbursement = useSelector(
    (state) => state.app.GETALLREIMBURSEMENTs
  );

  useEffect(() => {
    dispatch(getAllReimbursement(token));
  }, [dispatch, token]);

  const handleApprove = (reimbursementId) => {
    const bill = AllReimbursement.claims.find(
      (item) => item.claimID === reimbursementId
    );

    if (!bill) return; // Ensure the bill exists

    // Dispatch the approval action
    dispatch(ApprovedReimbursement(token, reimbursementId, "Approved"));

    const updatedBills = AllReimbursement.claims.map((item) =>
      item.claimID === reimbursementId ? { ...item, status: "Approved" } : item
    );
    dispatch({ type: "UPDATE_REIMBURSEMENT_STATUS", payload: updatedBills });

    toast({
      title: "Request Approved.",
      description: `You have approved the request ${bill.claimNumber}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    setDisabledActions((prevDisabledActions) => ({
      ...prevDisabledActions,
      [reimbursementId]: true,
    }));
  };

  const handleReject = (reimbursementId) => {
    const bill = AllReimbursement.claims.find(
      (item) => item.claimID === reimbursementId
    );

    if (!bill) return; // Ensure the bill exists

    // Dispatch the rejection action
    dispatch(ApprovedReimbursement(token, reimbursementId, "Rejected"));

    const updatedBills = AllReimbursement.claims.map((item) =>
      item.claimID === reimbursementId ? { ...item, status: "Rejected" } : item
    );
    dispatch({ type: "UPDATE_REIMBURSEMENT_STATUS", payload: updatedBills });

    toast({
      title: "Request Rejected.",
      description: `You have rejected the request ${bill.claimNumber}.`,
      status: "error",
      duration: 3000,
      isClosable: true,
    });

    setDisabledActions((prevDisabledActions) => ({
      ...prevDisabledActions,
      [reimbursementId]: true,
    }));
  };

  const removeBill = (index) => {
    const updatedBills = AllReimbursement.claims.filter((_, i) => i !== index);
    dispatch({ type: "REMOVE_REIMBURSEMENT", payload: updatedBills });
  };

  const handleDownload = (fileUrl) => {
    // Add the logic to download the document
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl?.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // console.log(`Downloading ${fileUrl}`);
  };

  return (
    <Box
      mt="70px"
      ml="280px"
      color="black"
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
    >
      <Heading
        fontSize={25}
        mb={"20px"}
        ml={4}
        pt={4}
        color={colorMode === "dark" ? "white" : "#000000"}
        textAlign="left"
      >
        Reimbursement Requests
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <TableContainer p={4}>
          <Table borderWidth="3px" borderColor="#e04141">
            <Thead>
              <Tr>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Claim Number
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                 Employee ID
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Reimbursement Type
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Bill Date
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Bill Number
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Bill Amount
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Document
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Status
                </Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {AllReimbursement?.claims?.map((bill, index) => (
                <Tr key={index}>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.claimID}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.employeeID}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.reimbursementType}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.billDate}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.billNumber}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.billAmount}
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    <IconButton
                      icon={<DownloadIcon />}
                      onClick={() => handleDownload(bill.billDocument)}
                      aria-label="Download"
                    />
                  </Td>
                  <Td
                    color={colorMode === "dark" ? "white" : "black"}
                    border="2px solid #e04141"
                  >
                    {bill.status}
                  </Td>
                  <Td>
                    <Box>
                      <Flex>
                        <Button
                          colorScheme="green"
                          size="sm"
                          mr={2}
                          onClick={() => handleApprove(bill.claimID)}
                          isDisabled={
                            bill.status !== "Pending" ||
                            disabledActions[bill.claimID]
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          mr={2}
                          onClick={() => handleReject(bill.claimID)}
                          isDisabled={
                            bill.status !== "Pending" ||
                            disabledActions[bill.claimID]
                          }
                        >
                          Reject
                        </Button>
                        <Text
                          onClick={() => removeBill(index)}
                          colorScheme="white"
                          w={"30px"}
                          h={"30px"}
                          cursor="pointer"
                          mt={1}
                        >
                          <FiMinusCircle size={20} color="red" />
                        </Text>
                      </Flex>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ReimbursementRequest;
