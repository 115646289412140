import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FormControl,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Container,
  TableContainer,
  Heading,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { GetComplaint } from "../../Redux/AppReducer/Action";

const Complaints = () => {
  const { colorMode } = useColorMode();

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const getAllcomplaint = useSelector((state) => state.app.GETCOMPLAINTs);
  console.log(getAllcomplaint);

  useEffect(() => {
    dispatch(GetComplaint(token));
  }, [dispatch, token]);

  return (
    <Box
      mt="70px"
      ml="280px"
      color="black"
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
      // color={colorMode === 'dark' ? 'white' : 'black'}
    >
      <Heading
        fontSize={25}
        mb={"20px"}
        ml={4}
        pt={4}
        color={colorMode === "dark" ? "white" : "#000000"}
        textAlign="left"
      >
        Complaints
      </Heading>
      <Box
       borderWidth="1px"
       borderRadius="md"
       p={2}
       ml={4}
       mr={4}
       boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
       background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        // bg={colorMode === "dark" ? "gray.700" : "white"}
        // color={colorMode === "dark" ? "white" : "black"}
      >
        <TableContainer>
          <Table borderWidth="3px" borderColor="#e04141">
            <Thead>
              <Tr>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>Employee ID</Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>Employee Name</Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>Subject</Th>
                <Th border="2px solid #e04141" color={"#e04141"} fontSize={14} width="300px">Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getAllcomplaint?.map((complaint) => (
                <Tr key={complaint.employeeID} borderColor="black">
                  <Td color={colorMode === "dark" ? "white" : "black"}  borderColor="black" border="2px solid #e04141">{complaint.employeeID}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"}  borderColor="black" border="2px solid #e04141">{complaint.employeeName}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"}  borderColor="black" border="2px solid #e04141">{complaint.subject}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"}  borderColor="black" border="2px solid #e04141">
                    <Textarea value={complaint.description} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Complaints;
