import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Select,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Heading,
  useColorMode,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveRequest, updateStatus } from "../../Redux/AppReducer/Action";

const LeaveApproval = () => {
  const [leaveActions, setLeaveActions] = useState({});
  const [disabledActions, setDisabledActions] = useState({});
  const toast = useToast();
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const leaveRequests = useSelector((state) => state.app.GETLEAVEREQUESTs);

  useEffect(() => {
    dispatch(getLeaveRequest(token));
  }, [dispatch, token]);

  const handleLeaveSubmit = (leaveId) => {
    const action = leaveActions[leaveId];
    if (!action) {
      toast({
        title: "Error",
        description: "Please fill the required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const leaveData = leaveRequests.find((leave) => leave.employeeID === leaveId);
    const status = String(action); // Ensure status is a string
    dispatch(updateStatus(token, leaveData.employeeID, status))
      .then(() => {
        toast({
          title: "Leave Request",
          description: "Submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setDisabledActions((prevDisabledActions) => ({
          ...prevDisabledActions,
          [leaveId]: true,
        }));
        dispatch(getLeaveRequest(token));
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to submit leave request.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleActionChange = (leaveId, action) => {
    setLeaveActions((prevActions) => ({
      ...prevActions,
      [leaveId]: action,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Box mt="70px" ml="280px" color="black" background={colorMode === "dark" ? "dark.700" : "#FCF7D2"}>
      <Heading fontSize={25} mb={"20px"} ml={4} pt={4} color={colorMode === "dark" ? "white" : "#000000"} textAlign="left">
        Leave Requests
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow={colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        background={colorMode === "dark" ? "dark.700" : "#FAE8E1"}
      >
        <TableContainer>
          <Table borderWidth="3px" borderColor="#E04141">
            <Thead>
              <Tr>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Employee Name</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Employee ID</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Department</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Type of Leave</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>From Date</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>To Date</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Reason</Th>
                <Th border="2px solid #E04141" w="150px" color={"#E04141"} fontSize={14}>Action</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Submit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {leaveRequests?.length > 0 && leaveRequests?.map((leave) => (
                <Tr key={leave.employeeID}>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{leave.employeeName}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{leave.employeeID}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{leave.department}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{leave.typeOfLeave}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{formatDate(leave.fromDate)}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141">{formatDate(leave.toDate)}</Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141"><Textarea w={"300px"}>{leave.reason}</Textarea></Td>
                  <Td color={colorMode === "dark" ? "white" : "black"} border="2px solid #E04141" w="150px">
                    <FormControl>
                      <Select
                        w={"150px"}
                        value={leaveActions[leave.employeeID] || leave.status || ""}
                        onChange={(e) => handleActionChange(leave.employeeID, e.target.value)}
                        isDisabled={leave.status === "Approved" || leave.status === "Rejected"}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </Select>
                    </FormControl>
                  </Td>
                  <Td borderColor="#E04141">
                    <Button
                      background={"#E04141"}
                      color={"#FECE01"}
                      fontSize={17}
                      padding="10px 25px"
                      onClick={() => handleLeaveSubmit(leave.employeeID)}
                      isDisabled={disabledActions[leave.employeeID]}
                    >
                      Submit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default LeaveApproval;
