import React from 'react';
import { Box, Heading, Text, useColorMode } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TicketHistory = () => {
  const currentMonth = new Date().getMonth();
  const ticketCounts = [12, 19, 3, 5, 2, 3, 9, 10, 15, 13, 7, 8];
  const {colorMode} = useColorMode();

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Tickets',
        data: ticketCounts,
        backgroundColor: ticketCounts.map((_, index) => 
          index === currentMonth ? '#c7bebd' : '#fc7979'
        ),
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Tickets: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <Box p={5}>
      <Heading mb={4} fontSize={22} textAlign={"left"} color={colorMode === "dark" ? "white" : "#000000"}>Ticket History</Heading>
      <Text mb={4} textAlign={"left"} color={colorMode === "dark" ? "white" : "#000000"} fontWeight={"bold"}>{ticketCounts[currentMonth]} / this month and last month {ticketCounts[currentMonth === 0 ? 11 : currentMonth - 1]}</Text>
      <Box p={5} borderWidth="1px" borderRadius="10px" background={colorMode === "dark" ? "dark.700" : "#fae8e1"} border="2px solid #e04141"             boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
      >
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default TicketHistory;
