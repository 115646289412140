import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Image,
  Text,
  VStack,
  Button,
  Heading,
  useDisclosure,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Input,
  useColorMode,
  FormControl,
  FormLabel,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployee, postUploadDocument } from "../../Redux/AppReducer/Action";
import Profile from "../../Components/Images/user-profile.png";

const handleDownload = (fileUrl) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileUrl.split("/").pop();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const initialDocuments = [
  {
    id: 1,
    name: "Resume",
    size: "2MB",
    extensionImage: "path_to_extension_image",
    downloadLink: "path_to_document",
  },
  {
    id: 2,
    name: "Portfolio",
    size: "5MB",
    extensionImage: "path_to_extension_image",
    downloadLink: "path_to_document",
  },
  {
    id: 3,
    name: "Cover Letter",
    size: "1MB",
    extensionImage: "path_to_extension_image",
    downloadLink: "path_to_document",
  },
  {
    id: 4,
    name: "Certificates",
    size: "3MB",
    extensionImage: "path_to_extension_image",
    downloadLink: "path_to_document",
  },
];

const Documents = () => {
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [selectedUser, setSelectedUser] = useState(null);
  const [documents, setDocuments] = useState(initialDocuments);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [employeeID, setEmployeeID] = useState('');
  const EmployeeData = useSelector((state) => state.app.ALLEMPLOYEEs);

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployee(token));
  }, [dispatch, token]);

  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onClose: onUploadModalClose,
  } = useDisclosure();
  const {
    isOpen: isOnboardingModalOpen,
    onOpen: onOnboardingModalOpen,
    onClose: onOnboardingModalClose,
  } = useDisclosure();
  const [isUploading, setIsUploading] = useState(false);
  const { colorMode } = useColorMode();

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files.length > 1 ? Array.from(files) : files[0],
    }));
  };

  const handleUploadFile = () => {
    if (!employeeID || Object.keys(selectedFiles).length === 0) {
      toast({
        title: 'Please fill out all fields',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);

    dispatch(postUploadDocument(token, employeeID, selectedFiles))
      .then(() => {
        setIsUploading(false);
        toast({
          title: 'Documents uploaded successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setSelectedFiles({});
        setEmployeeID('');
        onOnboardingModalClose();
      })
      .catch((error) => {
        setIsUploading(false);
        toast({
          title: 'Failed to upload documents',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseUserDocuments = () => {
    setSelectedUser(null);
  };

  const handleDelete = (docId) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((doc) => doc.id !== docId)
    );
  };

  return (
    <Box
      p={4}
      mt={"70px"}
      ml={"280px"}
      borderWidth={1}
      borderRadius="lg"
      bg={colorMode === "dark" ? "gray.800" : "#fcf7d2"}
      height={"auto"}
    >
      <Box
        borderRadius="10px"
        border="2px solid #e04141"
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        p={6}
      >
        <Heading fontSize="2xl" mb={8}>
          Documents
        </Heading>
        {!selectedUser && (
          <Box position="relative" height="10vh">
            <Button
              colorScheme="teal"
              onClick={onOnboardingModalOpen}
              position="absolute"
              top={1}
              right="10px"
            >
              Onboarding Employee Documents
            </Button>
          </Box>
        )}

        {selectedUser ? (
          <UserDocuments
            user={selectedUser}
            onClose={handleCloseUserDocuments}
            onDelete={handleDelete}
            documents={documents}
            onOpen={onUploadModalOpen}
          />
        ) : (
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            {EmployeeData?.map((user) => (
              <Box
                key={user.id}
                p={4}
                overflow="hidden"
                onClick={() => handleUserClick(user)}
                cursor="pointer"
                borderRadius="10px"
                border="2px solid #e04141"
                boxShadow={
                  colorMode === "dark"
                    ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                    : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
                }
                background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
              >
                <VStack>
                  <Image
                    borderRadius="full"
                    boxSize="80px"
                    objectFit={"cover"}
                    mx="auto"
                    src={user.profilePicture || Profile}
                  />
                  <Text fontWeight="bold">{user.employeeName}</Text>
                  <Text>{user.position}</Text>
                </VStack>
              </Box>
            ))}
          </Grid>
        )}

        <Modal isOpen={isUploadModalOpen} onClose={onUploadModalClose}>
          <ModalOverlay />
          <ModalContent mt={"250px"}>
            <ModalHeader>Upload Document</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                type="file"
                accept=".pdf,.doc,.docx"
                pt={1}
                onChange={handleFileChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleUploadFile}>
                Upload
              </Button>
              <Button onClick={onUploadModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOnboardingModalOpen} onClose={onOnboardingModalClose}>
          <ModalOverlay />
          <ModalContent
            mt={"65px"}
            maxW={"500px"}
            height={"1100px"}
            ml={"300px"}
          >
            <ModalHeader>Add Onboarding Documents</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="employeeID">
                <FormLabel>Employee ID</FormLabel>
                <Input
                  mb={3}
                  placeholder="Please Enter Employee ID"
                  type="text"
                  value={employeeID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                  width={"400px"}
                />
              </FormControl>

              <FormControl id="photo">
                <FormLabel>Photo</FormLabel>
                <InputGroup mb={3}>
                  <Input  type="file"
                  name="photo"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"/>
                 
                </InputGroup>
              </FormControl>

              <FormControl id="resume">
                <FormLabel>Resume</FormLabel>
                <InputGroup mb={3}>
                  <Input type="file"
                  name="resume"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                   accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                  />
                  
                </InputGroup>
              </FormControl>

              <FormControl id="idProof ">
                <FormLabel>ID Proof</FormLabel>
                <InputGroup mb={3}>
                  <Input type="file"
                  name="idProof"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                   />
   
                </InputGroup>
              </FormControl>

              <FormControl id="addressProof">
                <FormLabel>Address Proof</FormLabel>
                <InputGroup mb={3}>
                  <Input type="file"
                  name="addressProof"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                   />
                  
                </InputGroup>
              </FormControl>

              <FormControl id="educationalDocument">
                <FormLabel>Educational Documents</FormLabel>
                <InputGroup mb={3}>
                  <Input
                    type="file"
                    name="educationalDocument"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    multiple
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="priorExperience">
                <FormLabel>Prior Experience</FormLabel>
                <InputGroup mb={3}>
                  <Input
                    type="file"
                    name="priorExperience"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    multiple
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="panCard">
                <FormLabel>PAN Card</FormLabel>
                <InputGroup mb={3}>
                  <Input  type="file"
                  name="panCard"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  />
                  
                </InputGroup>
              </FormControl>

              <FormControl id="salarySlip">
                <FormLabel>Salary Slip</FormLabel>
                <InputGroup mb={3}>
                  <Input
                    type="file"
                    name="salarySlip"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    multiple
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="bankStatement">
                <FormLabel>Bank Statement</FormLabel>
                <InputGroup mb={3}>
                  <Input  type="file"
                  name="bankStatement"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" />
                  
                </InputGroup>
              </FormControl>

              <FormControl id="otherDocuments">
                <FormLabel>Other Document</FormLabel>
                <InputGroup mb={3}>
                  <Input
                    type="file"
                    name="otherDocuments"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    multiple
                  />
                </InputGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isUploading}
                loadingText="Uploading"
                colorScheme="blue"
                onClick={handleUploadFile}
              >
                Upload
              </Button>
              <Button variant="ghost" onClick={onOnboardingModalClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

const UserDocuments = ({ user, onClose, onDelete, documents, onOpen }) => {
  const { colorMode } = useColorMode();

  return (
    <Box>
      <Heading fontSize="2xl" mb={4}>
        {user.employeeName}'s Documents
      </Heading>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {documents.map((doc) => (
          <Box
            key={doc.id}
            p={4}
            borderRadius="10px"
            border="2px solid #e04141"
            boxShadow={
              colorMode === "dark"
                ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
                : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
            }
            background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
          >
            <VStack>
              <Image src={doc.extensionImage} boxSize="50px" />
              <Text fontWeight="bold">{doc.name}</Text>
              <Text>{doc.size}</Text>
              <Button
                colorScheme="blue"
                onClick={() => handleDownload(doc.downloadLink)}
                size="sm"
              >
                Download
              </Button>
              <Button
                colorScheme="red"
                onClick={() => onDelete(doc.id)}
                size="sm"
              >
                Delete
              </Button>
            </VStack>
          </Box>
        ))}
      </Grid>
      <Button
        colorScheme="teal"
        mt={4}
        onClick={onOpen}
        display="block"
        ml="auto"
      >
        Upload Document
      </Button>
      <Button mt={4} onClick={onClose}>
        Back to Employee List
      </Button>
    </Box>
  );
};

export default Documents;
