import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Box, extendTheme, ChakraProvider, CSSReset, ColorModeScript } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  styles: {
    global: (props) => ({
      ".react-calendar": {
        width: "100%",
        height: "100%",
        border: `2px solid ${props.colorMode === "light" ? "#e53e3e" : "#e04141"}`,
        background: props.colorMode === "dark" ? "#1a202c" : "#fae8e1",
        borderRadius: "12px",
        boxShadow: props.colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px",
        color: props.colorMode === "dark" ? "#E2E8F0" : "black",
      },
      ".react-calendar__tile--now": {
        background: "#3182ce !important",
        color: "white !important",
        borderRadius: "6px",
      },
      ".react-calendar__tile--active": {
        background: "#2b6cb0 !important",
        color: "white !important",
        borderRadius: "6px",
      },
      ".react-calendar__tile--custom-holiday": {
        background: "#e53e3e !important",
        color: "white !important",
        borderRadius: "6px",
      },
      ".react-calendar__navigation button": {
        color: props.colorMode === "dark" ? "#63b3ed" : "#2b6cb0",
      },
    }),
  },
});

const CustomCalendar = ({ leave }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const customHolidays = leave === undefined ? [] : leave.map((ele) => new Date(ele));

  const isCustomHoliday = (date) => {
    return customHolidays.some(
      (holiday) => holiday.toDateString() === date.toDateString()
    );
  };

  const tileClassName = ({ date }) => {
    return isCustomHoliday(date) ? "react-calendar__tile--custom-holiday" : null;
  };

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <CSSReset />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box width="100%" maxWidth="400px">
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileClassName={tileClassName}
          />
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default CustomCalendar;
