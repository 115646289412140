import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployee } from "../../Redux/AppReducer/Action";


const EmployeeList = () => {
  const { colorMode } = useColorMode();
  const token = useSelector((store) => store.auth.token);
  const employeeData = useSelector((store) => store.app.ALLEMPLOYEEs);
  console.log(employeeData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEmployee(token));
  }, [dispatch, token]);

  return (
    <Box width={"100%"} maxHeight={"60vh"} overflowY={"auto"}>
      <Box
        color={colorMode === "dark" ? "white" : "black"}
        fontSize={18}
        fontWeight={700}
      >
        EMPLOYEE LIST{" "}
      </Box>
      <Box p={5}>
        <Divider />
        <Table
          maxWidth={"100%"}
          overflowY={"scroll"}
          background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
          borderRadius="10px"
          border="2px solid #e04141"
          boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

          
        >
          <Thead>
            <Tr>
              <Th color={"teal"} textAlign={"left"}>
                Profile
              </Th>
              <Th color={"teal"} textAlign={"left"}>
                Name
              </Th>
              <Th color={"teal"} textAlign={"left"}>
                Email
              </Th>
              <Th color={"teal"} textAlign={"left"}>
                Phone Number
              </Th>
            </Tr>
          </Thead>
          <Tbody color={colorMode === "dark" ? "white" : "black"}>
            {employeeData?.map((employee) => (
              <Tr key={employee.employeeId}>
                <Td>
                  <Image
                    src={employee.profilePicture}
                    alt={`Employee Photo - ${employee.employeeName}`}
                    borderRadius="full"
                    boxSize="20px"
                  />
                </Td>
                <Td>{employee.employeeName}</Td>
                <Td>{employee.email}</Td>
                <Td>{employee.contactInfo}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default EmployeeList;
