import React from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import UpcomingEvents from './EmployeeBirthday';

const Birthday = () => {
  return (
    <Box alignItems={"center"} width={"100%"}>
      <UpcomingEvents/>
    
    </Box>
  );
};

export default Birthday;
