import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const Timer = ({setIsCheckedIn, setCheckInTime, setCheckOutTime}) => {

    const [elapsedTime, setElapsedTime] = useState(0);
  const getAttendance = useSelector((state) => state.app.ALLATTENDANCEs);
  
    

    useEffect(() => {
        let interval;
    
        if (getAttendance && getAttendance.checkInTime) {
          const checkInDate = new Date(getAttendance.checkInTime);
          setCheckInTime(checkInDate);
          setIsCheckedIn(true);
    
          if (!getAttendance.checkOutTime) {
            interval = setInterval(() => {
              setElapsedTime(new Date() - checkInDate.getTime());
            }, 1000);
          } else {
            const checkOutDate = new Date(getAttendance.checkOutTime);
            setCheckOutTime(checkOutDate);
            setElapsedTime(checkOutDate.getTime() - checkInDate.getTime());
            setIsCheckedIn(false);
          }
        }
    
        return () => clearInterval(interval);
      }, [getAttendance]);

      const formatTime = (time) => {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
    
        return `${hours}Hr: ${minutes}Min: ${seconds}Sec`;
      };


  return (
    <Text fontSize="2xl" textAlign={"left"} padding={"10px"}>
    {formatTime(elapsedTime)}
  </Text>
  )
}

export default Timer