import React, { Children } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const Private = ({ Children }) => {

   const data = useSelector((store) => store.auth.user)
   if (data?.employeeName === undefined) {
      return <Navigate to="/login" /> 
   } else {
      return Children
   }

}  

export default Private
