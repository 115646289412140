import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    VStack,
    useToast,
    Stack,
    Flex,
    Select,
    Text,
    Box,
    useColorMode,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { createLeave, getUserLeave } from '../../../Redux/AppReducer/Action';

const LeaveApplication = () => {
    const [typeOfLeave, setLeaveType] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [reason, setReason] = useState('');

    const toast = useToast();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const userDepartment = useSelector((state) => state.auth.user?.department); // Assuming user department is stored here
    const { colorMode } = useColorMode();

    const handleLeaveSubmit = () => {
        if (!typeOfLeave || !fromDate || !toDate || !reason) {
            toast({
                title: 'Error',
                description: 'Please fill all the required fields.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const leaveData = { typeOfLeave, fromDate, toDate, department: userDepartment, reason };
        dispatch(createLeave(token, leaveData));

        setLeaveType('');
        setFromDate('');
        setToDate('');
        setReason('');

        toast({
            title: 'Leave Applied',
            description: 'Leave applied successfully!',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
        dispatch(getUserLeave(token))
    };

    return (
        <VStack
            pt={"20px"}
            spacing={4}
            color={colorMode === 'dark' ? 'white' : 'black'}
            margin="auto"
            bg={colorMode === 'dark' ? 'dark.800' : '#fcf7d2'}
        >
            <Box
                p={4}
                borderWidth="1px"
                borderRadius="md"
                border="2px solid #e04141"
                width="95%"
                margin="auto"
                bg={colorMode === 'dark' ? 'dark.700' : '#fae8e1'}
                boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
                <Box>
                    <Text textAlign="left" fontWeight="600" fontSize="20px" color={"#e04141"}>
                        Apply for A Leave
                    </Text>
                </Box>

                <Stack direction="row" spacing={3} mb="20px" width="100%" margin="auto" mt="20px">
                    <FormControl>
                        <FormLabel>Type of Leave</FormLabel>
                        <Select
                            placeholder="Select Type of Leave"
                            borderColor={"#e04141"}
                            value={typeOfLeave}
                            onChange={(e) => setLeaveType(e.target.value)}
                        >
                            <option value="LOP">LOP</option>
                            <option value="PL">PL</option>
                            <option value="Special">Special</option>
                            <option value="Casual">Casual</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>From Date</FormLabel>
                        <Input
                            type="date"
                            name="date"
                            borderColor={"#e04141"}
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>To Date</FormLabel>
                        <Input
                            type="date"
                            name="date"
                            borderColor={"#e04141"}
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Department</FormLabel>
                        <Select
                            borderColor={"#e04141"}
                            placeholder="Department"
                            isReadOnly
                        >
                            <option value={userDepartment}>{userDepartment}</option>
                        </Select>
                    </FormControl>
                </Stack>
                <Flex gap="20px" width="100%" margin="auto" mt="20px">
                    <FormControl mb={4} flex="3">
                        <FormLabel>Reason for Leave</FormLabel>
                        <Textarea
                            placeholder="Enter reason for leave"
                            borderColor={"#e04141"}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        <Button background={"#e04141"} color={"#FECE01"} padding="10px 25px" mt="10px" onClick={handleLeaveSubmit}>
                            Apply Leave
                        </Button>
                    </FormControl>
                </Flex>
            </Box>
        </VStack>
    );
};

export default LeaveApplication;
