import React from 'react'
import Login from '../Components/Login/Login';
import Home from '../Components/Home/Home';
import Navbar from '../Components/Navbar/Navbar';
import Sidebar from '../Components/Navbar/Sidebar';
import { Box, Text } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom/dist';
import UAN from '../screens/User/UAN';
import PaySlip from '../screens/User/PaySlip';
import Employee from '../screens/Admin/Employee';
import Document from '../screens/User/Document';
import Hike from '../screens/User/Hike';
import Awards_Rewards from '../screens/User/Awards_Rewards';
import Rise_Complaint from '../screens/User/Rise_Complaint';
import Attendance from '../screens/User/Attendance/Attendance';
import Leave from '../screens/User/Leave/Leave';
import Reimbursement from '../screens/User/Reimbursement';
// import Performance from '../screens/User/Performance';
import PunchIn from '../Components/Home/Punch/PunchIn';
import Payroll from '../screens/Admin/Payroll';

import Resign from '../screens/User/Resign';
import AboutUs from '../screens/User/AboutUs';
import Ticket  from '../screens/User/Ticket';
import MyAttendancePage from '../screens/Admin/AttendancePage';
import EmployeeProfilePage from '../screens/User/Employee_Profile';
import LeaveApproval from '../screens/Admin/LeaveApproval';
import Private from './Private';
import Tickets from '../screens/Admin/Tickets';
import AppraisalRequest from '../screens/Admin/AppraisalRequeat';
import ReimbursementPage from '../screens/Admin/Reimbursement';
import Awards from '../screens/Admin/Awards';
import EmployeeDashboard from '../screens/User/EmployeeDashboard';
import Events from '../screens/Admin/Events';
import Referral from '../screens/User/Referral';
import ReferralRequest from '../screens/Admin/ReferralRequests';
import Particle from '../Components/Login/Particle';
import Complaints from '../screens/Admin/Complaints';
import Documents from '../screens/Admin/Documents.js';
import Settings from '../screens/User/Settings';


const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<><Private/><Navbar/> <Sidebar/> <Home/></>} />
      <Route path='/login' element={<><Login /></>} />
      <Route path='/employee' element={<><Navbar/> <Sidebar/><Employee/></>} />
      <Route exact path='/uan' element={<><Navbar/> <Sidebar/><UAN/> </>} />
      <Route exact path='/payslip' element={<><Navbar/> <Sidebar/><PaySlip/> </>} />
      <Route path='/document' element={<><Navbar/> <Sidebar/><Document/></>} />
      <Route path='/hike' element={<><Navbar/> <Sidebar/><Hike/></>} />
      <Route path='/awardsandrewards' element={<><Navbar/> <Sidebar/><Awards_Rewards/></>} />
      <Route path='/rise and complaints' element={<><Navbar/> <Sidebar/><Rise_Complaint/></>} />
      <Route path='/attendance' element={<><Navbar/> <Sidebar/><Attendance/></>} />
      <Route path='/attendancepage' element={<><Navbar/> <Sidebar/><MyAttendancePage/></>} />
      <Route path='/leave' element={<><Navbar/> <Sidebar/><Leave/></>} />
      {/* <Route path='/performance' element={<><Navbar/> <Sidebar/><Performance/></>} /> */}
      <Route path='/reimbursement' element={<><Navbar/> <Sidebar/><Reimbursement/></>} />
      <Route path='/payroll' element={<><Navbar/> <Sidebar/><Payroll /></>} />
      <Route path='/referral' element={<><Navbar/> <Sidebar/><Referral /></>} />
      <Route path='/punchin' element={<><Navbar/> <Sidebar/><PunchIn /></>} />
      <Route path='/resign' element={<><Navbar/> <Sidebar/><Resign /></>} />
      <Route path='/about' element={<><Navbar/> <Sidebar/><AboutUs /></>} />
      <Route path='/ticket' element={<><Navbar/> <Sidebar/><Ticket /></>} />
      <Route path='/employeeprofilepage' element={<><Navbar/> <Sidebar/><EmployeeProfilePage /></>} />
      <Route path='/leaveapproval' element={<><Navbar/> <Sidebar/><LeaveApproval /></>} />
      <Route path='/tickets' element={<><Navbar/> <Sidebar/><Tickets /></>} />
      <Route path='/appraisalrequest' element={<><Navbar/> <Sidebar/><AppraisalRequest /></>} />
      <Route path='/reimbursementrequest' element={<><Navbar/> <Sidebar/><ReimbursementPage /></>} />

      <Route path='/awards' element={<><Navbar/> <Sidebar/><Awards /></>} />
      <Route path='/employeedashboard' element={<><Navbar/> <Sidebar/><EmployeeDashboard/></>} />

      <Route path='/events' element={<><Navbar/> <Sidebar/><Events /></>} />
      <Route path='/referralrequests' element={<><Navbar/> <Sidebar/><ReferralRequest /></>} />
      <Route path='/complaints' element={<><Navbar/> <Sidebar/><Complaints /></>} />
      <Route path='/documents' element={<><Navbar/> <Sidebar/><Documents /></>} />
      <Route path='/settings' element={<><Navbar/> <Sidebar/><Settings /></>} />







    </Routes>
  ) 
}

export default AllRoutes
