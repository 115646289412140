import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword } from "../../Redux/AuthReducer/Action";
import { useNavigate } from "react-router-dom"; 

const Settings = () => {
  const { colorMode } = useColorMode();
  const [employeeId, setEmployeeId] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const userEmployeeID  = useSelector((state) => state.auth.user?.employeeID);


  const handleSubmit = () => {
    if (!employeeId || !oldPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage("Please fill all fields");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage(
        "New password and confirm new password should be the same"
      );
      return;
    }

    dispatch(ResetPassword(token, employeeId, oldPassword, newPassword));
    console.log( employeeId, oldPassword, newPassword)

    setErrorMessage("");

    toast({
      description: "Password Changed Successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/login")

    resetForm();
  };

  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const resetForm = () => {
    setErrorMessage("");
    setEmployeeId("");
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmNewPassword(false);
  };

  return (
    <Box
      mt="30px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#fcf7d2"}
      borderWidth="1px"
      height={"95vh"}
    >
      <Box
        border="2px solid #e04141"
        borderRadius="md"
        p={2}
        mt={"70px"}
        ml={"250px"}
        mr={4}
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        width={"40%"}
        pt={"10px"}
        mb={4}
        pl={"17px"}
        bg={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <FormLabel
          fontSize="xl"
          mt={1}
          mb={4}
          pl={"120px"}
          color={colorMode === "dark" ? "white" : "black"}
        >
          Reset Password
        </FormLabel>

        <FormControl id="EmployeeID" mb={4}>
                <FormLabel>EmployeeID</FormLabel>
                <Select
                  borderColor={"#e04141"}
                  placeholder="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                >
                  <option value={userEmployeeID}>{userEmployeeID}</option>
                </Select>
              </FormControl>
              
        <FormControl mb={4}>
          <FormLabel>Old Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#e04141"}
              type={showOldPassword ? "text" : "password"}
              placeholder="Enter your old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleOldPasswordVisibility}
                aria-label={showOldPassword ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#e04141"}
              type={showNewPassword ? "text" : "password"}
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleNewPasswordVisibility}
                aria-label={showNewPassword ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Confirm New Password</FormLabel>
          <InputGroup>
            <Input
              borderColor={"#e04141"}
              type={showConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm your new password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={showConfirmNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={handleConfirmNewPasswordVisibility}
                aria-label={
                  showConfirmNewPassword ? "Hide password" : "Show password"
                }
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        {errorMessage && (
          <Text color="red.500" mt={2}>
            {errorMessage}
          </Text>
        )}

        <Button mr={3} onClick={handleSubmit} color={"#FECE01"} background={"#e04141"}>
          Submit
        </Button>
        <Button onClick={resetForm}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default Settings;
