import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  HStack,
  Grid,
  useColorMode,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import {
  GetStatistics,
  GetTickets,
  GetUpdateStatus,
} from "../../Redux/AppReducer/Action";

const Tickets = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const token = useSelector((state) => state.auth.token);
  const GetStatisticsData = useSelector((state) => state.app.GETSTATISTICSs);

  const gettickets = useSelector((state) => state.app.GETTICKETSs);
  const [tickets, setTickets] = useState([gettickets]);

  useEffect(() => {
    dispatch(GetStatistics(token));
    dispatch(GetTickets(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (gettickets) {
      setTickets(gettickets);
    }
  }, [gettickets]);

  const [currentPage, setCurrentPage] = useState(0);
  const ticketsPerPage = 1000;

  const handleStatusSelect = async (ticketId, status) => {
    console.log(`ticket ID: ${ticketId} to status: ${status}`);
    await dispatch(GetUpdateStatus(token, ticketId, status));
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket._id === ticketId ? { ...ticket, status } : ticket
      )
    );
  };

  const displayedTickets = tickets?.slice(
    currentPage * ticketsPerPage,
    (currentPage + 1) * ticketsPerPage
  );

  const doughnutData = (value) => ({
    datasets: [
      {
        data: [value, 50 - value],
        backgroundColor: ["#F56565", "#c7bebd"],
      },
    ],
  });

  const solvedTickets = (value) => ({
    datasets: [
      {
        data: [value, 50 - value],
        backgroundColor: ["#F56565", "#c7bebd"],
      },
    ],
  });

  const openTickets = (value) => ({
    datasets: [
      {
        data: [value, 50 - value],
        backgroundColor: ["#F56565", "#c0c0c0"],
      },
    ],
  });

  const pendingTickets = (value) => ({
    datasets: [
      {
        data: [value, 50 - value],
        backgroundColor: ["#F56565", "#c0c0c0"],
      },
    ],
  });

  return (
    <Box p={"8px"} mt={"70px"} ml={"280px"} background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}>
      <Box
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        borderRadius="md"
        mb={6}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
      >
        <Flex>
          <Heading
            fontSize={25}
            mb={"20px"}
            ml={4}
            pt={4}
            color={colorMode === "dark" ? "white" : "#000000"}
            textAlign="left"
          >
            Tickets
          </Heading>
        </Flex>

        <Flex
          width={"1000px"}
          height={"100%"}
          m={"20px"}
          bg={colorMode === "dark" ? "dark.700" : "white"}
          borderRadius="10px"
          padding={"20px"}
          boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
          backdropFilter={"blur(4px)"}
          flexDirection={"column"}
          background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
          border="2px solid #e04141"
        >
          <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={6}>
            <Box
              width={230}
              height={150}
              p={5}
              mr={1}
              ml={1}
              shadow="md"
              borderWidth="1px"
              borderRadius={10}
              textAlign="center"
              backgroundColor={colorMode === "dark" ? "dark.700" : "white"}
              borderLeft="4px solid #F56565"
              boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
              <Flex>
                <Box>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    New Tickets
                  </Text>
                  <Text
                    fontWeight={"bold"}
                    textAlign={"left"}
                    fontSize={30}
                    mt={2}
                  >
                    {GetStatisticsData?.newTickets}
                  </Text>
                </Box>
                <Box>
                  <Doughnut
                    height={"120px"}
                    width={"120px"}
                    data={doughnutData(GetStatisticsData?.newTickets, "New")}
                  />
                </Box>
              </Flex>
            </Box>
            <Box
              width={230}
              height={150}
              p={5}
              mr={1}
              shadow="md"
              borderWidth="1px"
              borderRadius={10}
              textAlign="center"
              backgroundColor={colorMode === "dark" ? "dark.700" : "white"}
              borderLeft="4px solid #F56565"
              boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
              <Flex>
                <Box>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    Solved Tickets
                  </Text>
                  <Text
                    fontWeight={"bold"}
                    textAlign={"left"}
                    fontSize={30}
                    mt={2}
                  >
                    {GetStatisticsData?.solvedTickets}
                  </Text>
                </Box>
                <Box>
                  <Doughnut
                    height={"120px"}
                    width={"120px"}
                    data={solvedTickets(
                      GetStatisticsData?.solvedTickets,
                      "Solved"
                    )}
                  />
                </Box>
              </Flex>
            </Box>
            <Box
              width={230}
              height={150}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius={10}
              textAlign="center"
              backgroundColor={colorMode === "dark" ? "dark.700" : "white"}
              borderLeft="4px solid #F56565"
              boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
              <Flex>
                <Box>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    Progress Tickets
                  </Text>
                  <Text
                    fontWeight={"bold"}
                    fontSize={30}
                    mt={2}
                    textAlign={"left"}
                  >
                    {GetStatisticsData?.openTickets}
                  </Text>
                </Box>
                <Box>
                  <Doughnut
                    height={"120px"}
                    width={"120px"}
                    data={openTickets(GetStatisticsData?.openTickets, "Open")}
                  />
                </Box>
              </Flex>
            </Box>
            <Box
              width={230}
              height={150}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius={10}
              textAlign="center"
              backgroundColor={colorMode === "dark" ? "dark.700" : "white"}
              borderLeft="4px solid #F56565"
              boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

            >
              <Flex>
                <Box>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    Pending Tickets
                  </Text>
                  <Text
                    fontWeight={"bold"}
                    textAlign={"left"}
                    fontSize={30}
                    mt={2}
                  >
                    {GetStatisticsData?.pendingTickets}
                  </Text>
                </Box>
                <Box>
                  <Doughnut
                    height={"120px"}
                    width={"120px"}
                    data={pendingTickets(
                      GetStatisticsData?.pendingTickets,
                      "Pending"
                    )}
                  />
                </Box>
              </Flex>
            </Box>
          </Grid>
        </Flex>
        <Box
          mt={"20px"}
          borderWidth="1px"
          borderRadius="10px"
          mr={5}
          ml={5}
          w={"auto"}
        >
          <TableContainer>
            <Table
              borderWidth="3px"
              borderColor="#e04141"
              border="2px solid #e04141"
              fontSize={14}
            >
              <Thead>
                <Tr>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    EMP ID
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Ticket Id
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Ticket Subject
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Created Date
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Priority
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedTickets?.map((ticket) => (
                  <Tr key={ticket?._id} border="2px solid #e04141" fontSize={14}>
                    <Td border="2px solid #e04141" fontSize={14}>
                      {ticket?.employeeID}
                    </Td>
                    <Td border="2px solid #e04141" fontSize={14}>
                      {ticket?._id}
                    </Td>
                    <Td border="2px solid #e04141" fontSize={14}>
                      {ticket?.subject}
                    </Td>
                    <Td border="2px solid #e04141" fontSize={14}>
                      {new Date(ticket?.createdDate).toLocaleString()}
                    </Td>
                    <Td border="2px solid #e04141" fontSize={14}>
                      <HStack>
                        {ticket?.priorityIcon}
                        <Text>{ticket?.priority}</Text>
                      </HStack>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={Button}
                          background={"#e04141"}
                          color={"#FECE01"}
                          rightIcon={<ChevronDownIcon />}
                          isDisabled={ticket?.status === "Resolved"}
                        >
                          <HStack>
                            {ticket?.statusIcon}
                            <Text>{ticket?.status}</Text>
                          </HStack>
                        </MenuButton>
                        <MenuList>
                          {console.log(ticket)}
                          <MenuItem
                            icon={<FaRegDotCircle color="red" />}
                            onClick={() =>
                              handleStatusSelect(ticket?._id, "Pending")
                            }
                          >
                            Pending
                          </MenuItem>
                          <MenuItem
                            icon={<FaRegDotCircle color="blue" />}
                            onClick={() =>
                              handleStatusSelect(ticket?._id, "Resolved")
                            }
                           
                          >
                            Resolved
                          </MenuItem>
                         
                          
                          <MenuItem
                            icon={<FaRegDotCircle color="green" />}
                            onClick={() =>
                              handleStatusSelect(ticket?._id, "In Progress")
                            }
                          >
                            In Progress
                          </MenuItem>
                         
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Tickets;
