import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Input,
  Text,
  Center,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Payroll = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const user = useSelector((store) => store.auth.user);

  const [isEditing, setIsEditing] = useState(false);
  const [grossPayment, setGrossPayment] = useState("200000");
  const [employeeEPF, setEmployeeEPF] = useState("200");
  const [employee, setEmployee] = useState("300");
  const [insurance, setInsurance] = useState("0");
  const {colorMode} = useColorMode();

  const [employees, setEmployees] = useState([
    {
      employeeNetPayment: "₹17,25,654.00",
      PaymentDate: 30,
      NumberOfEmployee: "5000",
    },
    // Add more employee data as needed
  ]);

  const filterEmployees = () => {
    const filtered = employeesData.filter((employee) =>
      employee.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  useEffect(() => {
    filterEmployees();
  }, [searchTerm]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Save the edited values to your backend or perform other actions
  };

  const handleInputChange = (value, setValue) => {
    // Validate input to allow numbers only
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setValue(value);
    }
  };

  return (
    <Box p={"8px"} mt={"70px"} ml={"280px"} background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}>
      <Flex justifyContent="space-between" mb={8}>
      <Heading textAlign={"left"} fontSize={24} pl={6} pt={5}>
        Welcome! {user?.employeeName == undefined ? <Link to="/login">Login</Link> : user?.employeeName}
      </Heading>
      </Flex>
      <Divider orientation="horizontal" borderWidth="1px" mb={8} />
      <Flex alignItems="center" mb={8} ml={6}>
        <Heading as="h4" size="md" mb={3}>
          Process Pay Run for April month
        </Heading>
        <Center>
          <Button colorScheme="green" ml={5} mb={3}>
            Approved
          </Button>
        </Center>
      </Flex>
      <Box
        p={10}
        borderLeft="4px solid green"
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        borderRadius="md"
        ml={6}
        mr={6}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Employee's Net Pay
            </Heading>
            <Text fontWeight="bold"> ₹17,25,23,654.00</Text>
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Payment Date
            </Heading>
            <Text fontWeight="bold">30 APR 2024</Text>
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              No. of Employees
            </Heading>
            <Text fontWeight="bold">728</Text>
          </Box>
          <Button colorScheme="blue">Download PDF</Button>
        </Flex>
      </Box>
      <Box mt={10} ml={6}>
        <Box display="flex" alignItems="flex-start">
          <Input
            width={"50%"}
            marginRight={"10px"}
            type="text"
            placeholder="Search Employee"
            value={searchTerm}
            borderColor= "blue.500"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button colorScheme="green" padding="10px" onClick={filterEmployees}>
            <Center>
              <FaSearch />
            </Center>
          </Button>
          {isEditing ? (
            <Button colorScheme="blue" ml={5} onClick={handleSaveClick}>
              Save
            </Button>
          ) : (
            <Button colorScheme="blue" ml={5} onClick={handleEditClick}>
              Edit Credentials{" "}
              <Center ml={2}>
                <MdEdit />
              </Center>{" "}
            </Button>
          )}
        </Box>
      </Box>
      <Box
        marginTop={10}
        p={10}
        borderLeft="4px solid green"
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        borderRadius="md"
        ml={6}
        mr={6}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Gross Payment
            </Heading>
            {isEditing ? (
              <Input
                value={`₹${grossPayment}`}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value.replace("₹", ""),
                    setGrossPayment
                  )
                }
              />
            ) : (
              <Text fontWeight="bold">₹{grossPayment}</Text>
            )}
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Employee EPF
            </Heading>
            {isEditing ? (
              <Input
                value={`₹${employeeEPF}`}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value.replace("₹", ""),
                    setEmployeeEPF
                  )
                }
              />
            ) : (
              <Text fontWeight="bold">₹{employeeEPF}</Text>
            )}
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Employee Deductions
            </Heading>
            {isEditing ? (
              <Input
                value={`₹${employee}`}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value.replace("₹", ""),
                    setEmployee
                  )
                }
              />
            ) : (
              <Text fontWeight="bold">₹{employee}</Text>
            )}
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontWeight={600} mb={2}>
              Insurance
            </Heading>
            {isEditing ? (
              <Input
                value={`₹${insurance}`}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value.replace("₹", ""),
                    setInsurance
                  )
                }
              />
            ) : (
              <Text fontWeight="bold">₹{insurance}</Text>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Payroll;
