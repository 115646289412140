import { Box, Button, Flex, Image, Link, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../Components/Images/UserImage.jpg";
import WeeklyAttendance from "./WeeklyAttendance";
import { checkInEmployee, checkOutEmployee, getAllAttendance } from "../../../Redux/AppReducer/Action";
import CheckIn from "../../../Components/Attendance/CheckIn";
import CheckOut from "../../../Components/Attendance/CheckOut";
import Timer from "../../../Components/Attendance/Timer";
import Profile from "../../../Components/Images/user-profile.png"

const Attendance = () => {
  const {colorMode} = useColorMode();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);

  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isCheckedIn, setIsCheckedIn] = useState(false);

  const getAttendance = useSelector((state) => state.app.ALLATTENDANCEs);

  const getCurrentDate = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const currentDate = new Date().toLocaleDateString("en-US", options);
    return currentDate;
  };

  useEffect(() => {
    dispatch(getAllAttendance(token));
  }, [dispatch, token]);

  

  return (

    <Box mt="70px" ml="280px" background={colorMode === "dark" ? "dark" : "#fcf7d2"} borderWidth="1px">
    <Box
      mt={"20px"}
      mr={"30px"}
      position={"relative"}
      ml={"40px"}
      borderWidth="1px"
      boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
      borderRadius="10px"
      border="2px solid #e04141"
      bg={colorMode === 'dark' ? 'dark.700' : '#fae8e1'}
    >
      <Flex gap={"20px"}>
        <Box width={"40%"} ml={"100px"}>
          <Flex gap={"10px"}>
            <Box width={"30%"} padding={"10px"} bg={colorMode === 'dark' ? 'dark.700' : '#fae8e1'}>
              <Image src={user.profilePicture || Profile} 
              borderRadius="full"
              boxSize="80px"
              objectFit="cover"
              mx="auto"
              mt={"10px"}/>
            </Box>
            <Box>
              <Text
                mt={"40px"}
                fontSize={"20px"}
                fontWeight={600}
                padding={"10px"}
              >
                {user?.employeeName ? user.employeeName : <Link to="/login">Name</Link>}
              </Text>
            </Box>
          </Flex>
          <Box textAlign={"left"} ml={"20px"} padding={"10px"}>
            {getCurrentDate()}
          </Box>
        </Box>

        <Box width={"40%"} ml={"100px"}>
          <Text
            textAlign={"left"}
            fontWeight={600}
            padding={"10px"}
            fontSize={"20px"}
          >
            Attendance
          </Text>
          <Timer setIsCheckedIn={setIsCheckedIn} setCheckInTime={setCheckInTime} setCheckOutTime={setCheckOutTime} />
          
          <Flex align="center" padding={"10px"}>
           <CheckIn setIsCheckedIn={setIsCheckedIn} />
           <CheckOut setIsCheckedIn={setIsCheckedIn} checkInTime={checkInTime} />
           
          </Flex>
        </Box>
      </Flex>
      <hr />
      <WeeklyAttendance />
    </Box>
    </Box>
  );
};

export default Attendance;
