import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { TiPlus } from "react-icons/ti";

const DocumentUpload = () => {
  const [documents, setDocuments] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const { colorMode } = useColorMode();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const newDocument = {
        fileName: selectedFile.name,
        file: URL.createObjectURL(selectedFile),
        uploadDate: new Date().toLocaleString(),
      };
      setDocuments([...documents, newDocument]);
      setSelectedFile(null);
      onClose();
    }
  };

  const handleDeleteClick = (index) => {
    setDocumentToDelete(index);
    setIsDeleteOpen(true);
  };

  const confirmDelete = () => {
    setDocuments(documents.filter((_, i) => i !== documentToDelete));
    setIsDeleteOpen(false);
    setDocumentToDelete(null);
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      mt="70px"
      ml="280px"
      background={colorMode === "dark" ? "dark" : "#FCF7D2"}
      w={"auto"}
      p={4}
    >
      <Box
        ml={"30px"}
        mr={7}
        width={"auto"}
        border="2px solid #e04141"
        position="relative"
        top="10px"
        boxShadow={
          colorMode === "dark"
            ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
            : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
        }
        borderWidth="1px"
        borderRadius="10px"
        p={5}
        bg={colorMode === "dark" ? "gray.800" : "#fae8e1"}
        color={colorMode === "dark" ? "white" : "black"}
      >
        <Box>
          <Flex justify="space-between" align="center" mb={5}>
            <Heading fontSize={25} color={"black"}>
              Documents
            </Heading>
            <Button color={"#FECE01"} background={"#e04141"} onClick={onOpen}>
              <TiPlus />
              Add Documents
            </Button>
          </Flex>
        </Box>

        <Box borderWidth="1px" borderRadius="10px" overflow="hidden">
          <HStack bg={colorMode === "dark" ? "gray.600" : "gray.100"} p={2}>
            <Text fontWeight="bold" w={"40%"} textAlign={"left"}>
              File Name
            </Text>
            <Text fontWeight="bold" w={"40%"} textAlign={"left"}>
              Uploaded on
            </Text>
            <Text fontWeight="bold" w={"20%"} textAlign={"left"}>
              Actions
            </Text>
          </HStack>
          <Divider />
          {documents.map((doc, index) => (
            <HStack
              key={index}
              p={2}
              bg={colorMode === "dark" ? "gray.800" : "#fae8e1"}
            >
              <Text textAlign={"left"} w={"40%"}>
                {doc.fileName}
              </Text>
              <Text textAlign={"left"} w={"40%"}>
                {doc.uploadDate}
              </Text>
              <HStack w={"20%"} spacing={2}>
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() => handleDownload(doc.file)}
                  aria-label="Download"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => handleDeleteClick(index)}
                  aria-label="Delete"
                />
              </HStack>
            </HStack>
          ))}
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          mt={"250px"}
          bg={colorMode === "dark" ? "gray.800" : "white"}
        >
          <ModalHeader>Upload Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              accept=".pdf,.doc,.docx"
              pt={1}
              onChange={handleFileChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpload}>
              Upload
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <ModalOverlay />
        <ModalContent
          mt={"250px"}
          bg={colorMode === "dark" ? "gray.800" : "white"}
        >
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this document?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DocumentUpload;
