import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  ChakraProvider,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  extendTheme,
  Flex,
  Textarea,
} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import LeaveApplication from "./ApplyLeave"
import LeaveHistory from "./LeaveHistory"


const Leave = () => {
  const [leaveStatus, setLeaveStatus] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const fromDate=useRef();
  const toDate=useRef();

  const submitLeaveRequest = () => {
    // Create a new leave request object
    const newLeaveRequest = {
      id: leaveData.length + 1, // You can use a more robust ID generation method
      status: leaveStatus,
      type: leaveType,
      fromDate:  fromDate.current.value, // Convert Date object to string in 'YYYY-MM-DD' format
      toDate:toDate.current.value,
    };

    // Update the leaveData array with the new leave request
    setLeaveData([...leaveData, newLeaveRequest]);

    // Reset form fields after submission
    setLeaveStatus('');
    setLeaveType('');
    fromDate.current.value=""
    toDate.current.value=""
    
  };

  const filteredLeaveData = leaveData.filter(
    (item) =>
      item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.fromDate.includes(searchTerm) ||
      item.toDate.includes(searchTerm)
  );


  return (

    <Box mt={"70px"} ml={"280px"} color={"black"}  >
      <LeaveApplication/>
     
      
      <LeaveHistory/>

    </Box >

  );
}

export default Leave;