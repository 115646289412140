import React from 'react';
import { Box, Center, Text, useColorMode } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from "react-redux";

Chart.register(ArcElement, Tooltip, Legend);

const EmployeeDonutChart = () => {
  const attendanceOverviewData = useSelector(
    (state) => state.app.ATTENDANCEOVERVIEWs
  );
  const { colorMode } = useColorMode();
  const data = {
    labels: ['Present', 'Absent'],
    datasets: [
      {
        data: [attendanceOverviewData?.totalPresentToday, attendanceOverviewData?.totalAbsentToday],
        backgroundColor: [ '#F56565', '#D9D22E'],
        hoverBackgroundColor: ['#F56565', '#D9D22E'],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: colorMode === 'dark' ? 'white' : 'black',
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    cutout: '60%',
  };

  return (
    <Center>
      <Box
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}       
        textAlign="center"
        // boxShadow={"4px 4px 4px 4px rgba(242, 242, 148)"}
        bg={colorMode === 'dark' ? 'dark.700' : '#fae8e1'}
        color={colorMode === 'dark' ? 'white' : 'black'}
        borderRadius="10px"
        border="2px solid #e04141"
        width="100%"
        height={"300px"}
        padding="20px"
        position="relative"
      >
        <Doughnut data={data} options={options} height={"300px"} width={"300px"} />
        <Box position="absolute" top="45%" left="70%" transform="translate(-50%, -50%)">
          <Text fontSize="xl" fontWeight="bold">
            Present
          </Text>
          <Text fontSize={22} fontWeight={"bold"} color={"#077777"}>{attendanceOverviewData?.totalPresentToday}</Text>
          <Text fontSize="xl" fontWeight="bold" mt="10px">
            Absent
          </Text>
          <Text fontSize={22} fontWeight={"bold"} color={"#077777"}>{attendanceOverviewData?.totalAbsentToday}</Text>
        </Box>
      </Box>
    </Center>
  );
};

export default EmployeeDonutChart;

