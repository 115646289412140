import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  useDisclosure,
  ModalFooter,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useColorMode,
} from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiPlus } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { CreateTickets, GetUserTickets } from "../../Redux/AppReducer/Action";

const Ticket = () => {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const GetUserTicketsData = useSelector((state) => state.app.GETUSERTICKETS);
  console.log(GetUserTicketsData);

  useEffect(() => {
    dispatch(GetUserTickets(token));
  }, [dispatch, token]);

  const [newTicket, setNewTicket] = useState({
    ticketId: "",
    subject: "",
    createdDate: format(new Date(), "yyyy-MM-dd"), // Set the current date without time
    priority: "Select Priority",
    priorityIcon: null,
    status: "Select Status",
    statusIcon: null,
  });

  const [tickets, setTickets] = useState([
    // Existing tickets
  ]);

  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const ticketsPerPage = 5;

  const validateForm = () => {
    let formErrors = {};
    if (!newTicket.priority || newTicket.priority === "Select Priority")
      formErrors.priority = "Priority is required";
    if (!newTicket.subject) formErrors.subject = "Ticket Subject is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handlePrioritySelect = (label, icon) => {
    setNewTicket((prevTicket) => ({
      ...prevTicket,
      priority: label,
      priorityIcon: icon,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "subject" && value.length > 30) return;
    setNewTicket((prevTicket) => ({ ...prevTicket, [name]: value }));
  };

  const handleAddTicket = () => {
    if (validateForm()) {
      const newTicketId = `#TKT-${String(tickets.length + 1).padStart(4, "0")}`;
      const formattedDate = format(
        new Date(newTicket.createdDate),
        "d MMM yyyy" // Format to display only the date
      );
      setTickets((prevTickets) => [
        ...prevTickets,
        {
          ...newTicket,
          ticketId: newTicketId,
          id: prevTickets.length + 1,
          createdDate: formattedDate,
        },
      ]);
      dispatch(CreateTickets(token, newTicket));
      setNewTicket({
        ticketId: "",
        subject: "",
        createdDate: format(new Date(), "yyyy-MM-dd"),
        priority: "Select Priority",
        priorityIcon: null,
        status: "Pending",
        statusIcon: <FaRegDotCircle color="blue" />,
      });
      dispatch(GetUserTickets(token, newTicket));
      onClose();
    }
  };

  const displayedTickets = GetUserTicketsData?.slice(
    currentPage * ticketsPerPage,
    (currentPage + 1) * ticketsPerPage
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <ChakraProvider>
      <Box
        mt={"70px"}
        ml={"280px"}
        background={colorMode === "dark" ? "dark" : "#fcf7d2"}
        pt={"20px"}
      >
        <Box
          background={colorMode === "dark" ? "dark" : "#fae8e1"}
          border="2px solid #e04141"
          borderRadius="10px"
          boxShadow={
            colorMode === "dark"
              ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
              : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
          }
          mr={5}
          ml={5}
          w={"auto"}
          p={4}
          mb={8}
        >
          <Flex justify="space-between" align="center" p={5}>
            <Heading fontSize={25} color={colorMode === "dark" ? "white" : "black"}>
              Tickets
            </Heading>
            <Button color={"#FECE01"} background={"#e04141"} onClick={onOpen}>
              <FiPlus color="#FECE01" />
              Add Ticket
            </Button>
          </Flex>
          <TableContainer mt={8}>
            <Table borderWidth="3px" borderColor="#e04141">
              <Thead>
                <Tr>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    EMP ID
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Ticket Id
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Ticket Subject
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Created Date
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Priority
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedTickets?.map((ticket) => (
                  <Tr key={ticket.id}>
                    <Td border="2px solid #e04141">{ticket.employeeID}</Td>
                    <Td border="2px solid #e04141">{ticket._id}</Td>
                    <Td border="2px solid #e04141">{ticket.subject}</Td>
                    <Td border="2px solid #e04141">
                      {formatDate(ticket.createdDate)}
                    </Td>
                    <Td border="2px solid #e04141">
                      <HStack>
                        {ticket.priorityIcon}
                        <Text>{ticket.priority}</Text>
                      </HStack>
                    </Td>
                    <Td border="2px solid #e04141">
                      <HStack>
                        {ticket.statusIcon}
                        <Text>{ticket.status}</Text>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent mt={"120px"}>
            <ModalHeader>Add New Ticket</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="subject" mb={4} isInvalid={errors.subject}>
                <FormLabel>Ticket Subject</FormLabel>
                <Input
                  name="subject"
                  value={newTicket.subject}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.subject}</FormErrorMessage>
              </FormControl>

              <FormControl id="createdDate" mb={4}>
                <FormLabel>Created Date</FormLabel>
                <Input
                  type="date" // Changed to type "date"
                  name="createdDate"
                  value={newTicket.createdDate} // Fixed value
                  readOnly // Make it read-only
                />
              </FormControl>

              <FormControl id="priority" mb={4} isInvalid={errors.priority}>
                <FormLabel>Priority</FormLabel>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    <HStack>
                      {newTicket.priorityIcon}
                      <Text>{newTicket.priority}</Text>
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      icon={<FaRegDotCircle color="red" />}
                      onClick={() =>
                        handlePrioritySelect(
                          "High",
                          <FaRegDotCircle color="red" />
                        )
                      }
                    >
                      High
                    </MenuItem>
                    <MenuItem
                      icon={<FaRegDotCircle color="yellow" />}
                      onClick={() =>
                        handlePrioritySelect(
                          "Medium",
                          <FaRegDotCircle color="yellow" />
                        )
                      }
                    >
                      Medium
                    </MenuItem>
                    <MenuItem
                      icon={<FaRegDotCircle color="green" />}
                      onClick={() =>
                        handlePrioritySelect(
                          "Low",
                          <FaRegDotCircle color="green" />
                        )
                      }
                    >
                      Low
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormErrorMessage>{errors.priority}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleAddTicket}>
                Add Ticket
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default Ticket;
