import { Box, Flex, Text, Button, useColorMode, Heading, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import Homecard from "./Homecard";
import { FaBeer } from "react-icons/fa";
import Logo from "../Images/UserImage.jpg";
import axios from "axios";
import authHeader from "../../auth-header";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CustomCalendar from "./CustomCalendar";
import UpcomingBirthdaysPage from "./EmployeeBirthday";
import Birthday from "./Birthday";
import EmployeeDonutChart from "./DoughnutChart";
import EmployeeList from "./EmployeeList";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDetails } from "../../Redux/AppReducer/Action";
import { store } from "../../Redux/store";
import { Doughnut, Bar } from "react-chartjs-2";
import TicketHistory from "./TicketHistory";

const Home = () => {
  const user = useSelector((store) => store.auth.user);
  const [HeadInformation, setHeadInformation] = useState({});
  const [EmployeeLate, setEmployeeLate] = useState([]);
  const [EmployeeAbsent, setEmployeeAbsent] = useState([]);
  let navigate = useNavigate();
  const token = useSelector((store) => store.auth.token);
  const DashboardData = useSelector((store) => store.app.DASHBOARD_DETAILSs);
  console.log(DashboardData);
  const year = new Date().getFullYear();
  const customHolidays = [
    "2024-01-26",
    "2024-03-08",
    "2024-04-09",
    "2024-05-01",
    "2024-08-15",
    "2024-08-26",
    "2024-09-07",
    "2024-09-16",
    "2024-10-02",
    "2024-10-12",
    "2024-11-02",
    "2024-12-25",
  ];

  // Fetching the data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardDetails(token));
  }, [dispatch, token]);


  const { colorMode } = useColorMode();

  const createChartData = (label, value, color) => ({
    labels: [label],
    datasets: [
      {
        label: label,
        data: [value, 100 - value],
        backgroundColor: [color, "#c7bebd"],
        hoverBackgroundColor: [color, "#c7bebd"],
      },
    ],
  });

  return (
    <Box
      mt={"70px"}
      ml={"280px"}
      background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}
      pt={"20px"}
    >
      <Flex
        width={"100wh"}
        height={"100%"}  
        m={"20px"}
        // bg={colorMode === "dark" ? "gray.700" : "white"}
        borderRadius="10px"
        padding={"20px"}
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
        backdropFilter={"blur(4px)"}
        flexDirection={"column"}
        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        border="2px solid #e04141"
      >
        <Box display={"flex"} height={"210px"} justifyContent={"space-between"}>
          <Box
            borderRadius="md"
            borderLeft="4px solid #e04141"
            boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
            p={3}
            mr={2}
            w={"33%"}
          >
            <Flex>
              <Box width={"35%"} mt={8}>
                <Text fontWeight={"bold"} color={colorMode === "dark" ? "white" : "black"}>
                  Total Employees
                </Text>
                <Text mt={4} fontWeight={"bold"} fontSize={30}>
                  {DashboardData?.totalEmployees}
                </Text>
              </Box>
              <Box width={"65%"}>
                <Doughnut
                  height={"180px"}
                  width={"180px"}
                  data={createChartData(
                    "Total Employees",
                    DashboardData?.totalEmployees,
                    "#F56565"
                  )}
                />
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="md"
            borderLeft="4px solid #e04141"
            // boxShadow={"0px 4px 4px rgba(250, 70, 57)"}
            boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
            p={3}
            mr={2}
            w={"33%"}
          >
            <Flex>
              <Box width={"35%"} mt={8}>
                <Text fontWeight={"bold"} color={colorMode === "dark" ? "white" : "black"}>
                  Total Departments
                </Text>
                <Text mt={4} fontWeight={"bold"} fontSize={30}>
                  {DashboardData?.totalDepartments}
                </Text>
              </Box>
              <Box width={"65%"}>
                <Doughnut
                  height={"200px"}
                  width={"200px"}
                  data={createChartData(
                    "Total Departments",
                    DashboardData?.totalDepartments,
                    "#F56565"
                  )}
                />
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="md"
            borderLeft="4px solid #e04141"
            // boxShadow={"0px 4px 4px rgba(250, 70, 57)"}
            boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}
            w={"33%"}
            p={3}
          >
            <Flex>
              <Box width={"35%"} mt={8}>
                <Text fontWeight={"bold"} color={colorMode === "dark" ? "white" : "black"}>
                  Total Active Tasks
                </Text>
                <Text mt={4} fontWeight={"bold"} fontSize={30}>
                  {DashboardData?.totalActiveTasks}
                </Text>
              </Box>
              <Box width={"65%"}>
                <Doughnut
                  height={"200px"}
                  width={"200px"}
                  data={createChartData(
                    "Total Active Tasks",
                    DashboardData?.totalActiveTasks,
                    "#F56565"
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Flex
        padding={"20px"}
        justifyContent={"left"}
        alignItems={"left"}
        gap={10}
      >
        <Box width={"60%"}>
          <EmployeeDonutChart />
        </Box>
        <Box width={"40%"}>
          <CustomCalendar leave={customHolidays} />
        </Box>
      </Flex>
      <Flex gap={5} marginTop={"10px"}>
        <Box width={"60%"}>
          <TicketHistory />
        </Box>
        <Box width={"40%"} position={"sticky"} top={"120px"}>
          <Birthday />
        </Box>
      </Flex>
      <EmployeeList />
    </Box>
  );
};
export default Home;