import React, { useEffect } from "react";
import {
  Flex,
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getReward } from "../../Redux/AppReducer/Action";
const AwardsAndRewardsPage = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const token = useSelector((state) => state.auth.token);
  const getallReward = useSelector((state) => state.app.GETREWARDs);
  console.log(getallReward);
  useEffect(() => {
    dispatch(getReward(token));
  }, [dispatch, token]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };
  return (
    <Box mt="70px" ml="280px" background={colorMode === "dark" ? "dark" : "#FCF7D2"} w={"auto"} p={4}>
      <Flex justify="space-between" align="center" p={5}>
        <Heading fontSize={25} mb={5} color={colorMode === "dark" ? "white" : "black"}>Awards And Rewards</Heading>
      </Flex>
      <Box
        borderWidth="1px"
        borderRadius="10px"
        overflow="hidden"
        border="2px solid #E04141"
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

        background={colorMode === "dark" ? "dark" : "#FAE8E1"}
      >
        <TableContainer p={5}>
          <Table borderWidth="3px" borderColor="#E04141">
            <Thead>
              <Tr>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Title</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Description</Th>
                <Th border="2px solid #E04141" color={"#E04141"} fontSize={14}>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getallReward?.rewards?.map((award) => (
                <Tr>
                  <Td border="2px solid #E04141" maxW={"250px"} textAlign={"inherit"}> {award.title}</Td>
                  <Td border="2px solid #E04141">
                    <Textarea value={award.description} />
                  </Td>
                  <Td border="2px solid #E04141">{formatDate(award.date)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default AwardsAndRewardsPage;