import React, { useState } from 'react';
import { Box, Heading, Button, FormControl, FormLabel, Textarea, Center, useToast } from '@chakra-ui/react';

const Resign = () => {
  const [reason, setReason] = useState('');
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!reason.trim()) {
      toast({
        title: "Error",
        description: "Please provide a reason for resignation",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setReason('');

    toast({
      title: "Successful",
      description: "your resignation has been triggered successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
      
    });
  };

  return (
    <Center mt={20}>
      <Box p={10} mt={10} borderWidth="1px" borderRadius="10px">
          <Heading as="h3" size="lg" mb={4}>
            Please provide the reason for Resignation
          </Heading>
          <form onSubmit={handleSubmit}>
            <FormControl id="reason" mb={4}>
              <FormLabel ml={10}>Reason</FormLabel>
              <Textarea
                type="text"
                width="500px"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
            <Button type="submit" colorScheme="teal" w="40%" mt={5}>
              Submit
            </Button>
          </form>
        </Box>
      </Center>
      
  );
};

export default Resign;