import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ChakraProvider,
  Box,
  Heading,
  Flex,
  VStack,
  Button,
  Select,
  FormControl,
  FormLabel,
  Input,
  InputRightAddon,
  InputGroup,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createHike, getUserHike } from "../../Redux/AppReducer/Action";

const HikeCard = ({ hikes, setHikes }) => {
  const { colorMode } = useColorMode();

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const userEmployeeID  = useSelector((state) => state.auth.user?.employeeID);
  const userDepartment = useSelector((state) => state.auth.user?.department); 


  const getallHike = useSelector((state) => state.app.GETUSERHIKEs);
  console.log(getallHike);

  useEffect(() => {
    dispatch(getUserHike(token));
  }, [dispatch, token]);

  const [hikePercentage, setHikePercentage] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState("");

  const handleHikePercentage = (event) => {
    const inputValue = event.target.value;
    // Allow only numeric input
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    if (numericValue.length <= 3 && numericValue <= 150) {
      setHikePercentage(numericValue);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setEmployeeId(inputValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (employeeId && department && hikePercentage) {
      // Ensure all required fields are present in the data object
      const data = {
        employeeId,
        department,
        expectedHikePercentage: hikePercentage,
      };
      dispatch(createHike(token, data));
      // Update hikes state with new hike data
      setHikes([...hikes, data]);
      // Clear form data after successful submission
      setEmployeeId("");
      setDepartment("");
      setHikePercentage("");
      dispatch(getUserHike(token));
    }
  };

  return (
    <Box>
        <Box
          width={"1020px"}
          p={10}
          boxShadow={
            colorMode === "dark"
              ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px"
              : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"
          }
          border="2px solid #e04141"
          borderRadius="10px"
        >
          <form onSubmit={handleSubmit}>
            <HStack spacing={10} w="100%">
              <FormControl id="EmployeeID">
                <FormLabel>EmployeeID</FormLabel>
                <Select
                  borderColor={"#e04141"}
                  placeholder="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                >
                  <option value={userEmployeeID}>{userEmployeeID}</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Department</FormLabel>
                <Select
                  borderColor={"#e04141"}
                  placeholder="Department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  <option value={userDepartment}>{userDepartment}</option>
                </Select>
              </FormControl>
              <FormControl id="ExpectedCTC">
                <FormLabel>Expected Hike</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    borderColor={"#e04141"}
                    placeholder="Hike Percentage"
                    value={hikePercentage}
                    onChange={handleHikePercentage}
                  />
                  <InputRightAddon
                    borderColor={"#e04141"}
                    background={"#e04141"}
                    color={"#FECE01"}
                  >
                    %
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
              <Button
                type="submit"
                background={"#e04141"}
                color={"#FECE01"}
                w="100%"
                mt={7}
              >
                Submit
              </Button>
            </HStack>
          </form>
          <Box mt={10}>
            <Table borderWidth="3px" borderColor="#e04141">
              <Thead>
                <Tr>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Employee ID
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Department
                  </Th>
                  <Th
                    border="2px solid #e04141"
                    color={"#e04141"}
                    fontSize={14}
                  >
                    Hike Percentage
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {getallHike?.map((hike, index) => (
                  <Tr key={index}>
                    <Td border="2px solid #e04141">{hike.employeeID}</Td>
                    <Td border="2px solid #e04141">{hike.department}</Td>
                    <Td border="2px solid #e04141">
                      {hike.expectedHikePercentage}%
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
    </Box>
  );
};

const Hike = () => {
  const { colorMode } = useColorMode();
  const [hikes, setHikes] = useState([]);

  return (
      <Box
        mt={"70px"}
        background={colorMode === "dark" ? "dark" : "#fcf7d2"}
      >
        <Box ml={"280px"}>
          <VStack>
            <Heading
              fontSize={25}
              textAlign={"left"}
              color={"black"}
              mt={"50px"}
              mb={"10px"}
            >
              Provide the below details to proceed
            </Heading>
            <Box wrap="wrap" background={colorMode === "dark" ? "dark" : "#fae8e1"} justify="center" back>
              <HikeCard hikes={hikes} setHikes={setHikes} />
            </Box>
          </VStack>
        </Box>
      </Box> 
  );
};

export default Hike;
