import { Button } from '@chakra-ui/react';
import React, { useState } from 'react'
import { checkOutEmployee, getAllAttendance } from '../../Redux/AppReducer/Action';
import { useDispatch, useSelector } from 'react-redux';

const CheckOut = ({setIsCheckedIn, checkInTime}) => {

    const [checkOutTime, setCheckOutTime] = useState(null);
    
    // const [checkInTime, setCheckInTime] = useState("");
    const token = useSelector((state)=>state.auth.token)
    const getAttendance = useSelector((state) => state.app.ALLATTENDANCEs);
    const dispatch=useDispatch()

    const handleCheckOut = () => {
        setCheckOutTime(new Date());
        const checkInDateTime = checkInTime.getTime();
        const checkOutDateTime = new Date().getTime();
        setIsCheckedIn(false);
        dispatch(checkOutEmployee(token))
        .then(()=>{
          dispatch(getAllAttendance(token))
        })
        
      };

  return (
    <Button
    onClick={handleCheckOut}
    colorScheme="red"
    isDisabled={getAttendance?.checkOutTime!=""}
  >
    Check Out
  </Button>
  )
}

export default CheckOut