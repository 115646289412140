import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  theme,
  CSSReset,
  Box,
  Flex,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';


const PunchPage = () => {

  const user=useSelector((store)=>store.auth.user)
  const [employeeData, setEmployeeData] = useState({
    id: 1,
    name: user.name,
    punchedIn: false,
  });

  const [currentTime, setCurrentTime] = useState(new Date());
  const [timer, setTimer] = useState(0);

  const handlePunch = () => {
    if (!employeeData.punchedIn) {
      // If punching in, start the timer
      setTimer(0); // Reset timer when punching in
      const timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
      setEmployeeData({ ...employeeData, punchedIn: true, timerInterval });
    } else {
      // If punching out, stop the timer
      clearInterval(employeeData.timerInterval);
      setEmployeeData({ ...employeeData, punchedIn: false, timerInterval: null });
    }
  };

  useEffect(() => {
    // Update current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Helper function to format seconds into HH:mm:ss
  const formatTimer = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = 
    `${String(hours).padStart(2, '0')}:
    ${String(minutes).padStart(2, '0')}:
    ${String(remainingSeconds).padStart(2, '0')}`;

    return formattedTime;
  };

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <PunchArea
        employeeData={employeeData}
        currentTime={currentTime}
        onPunch={handlePunch}
        timer={timer}
        formatTimer={formatTimer}
      />
    </ThemeProvider>
  );
};


const PunchArea = ({ employeeData, currentTime, onPunch, timer, formatTimer }) => {
  return (
    <Flex 
       
      minHeight='100vh'
      width='full' 
      align='center' 
      justifyContent='center'
    >
      <Box
        borderWidth={1}
        px={6}
        width='full'
        maxWidth='500px'
        borderRadius={8}
        textAlign='center'
        boxShadow='lg'
        bg='white'
        position={"absolute"}
        top={"35%"}
        left={"43%"}
      >
        <Box p={8} >
          <Heading fontSize='2xl' color='teal'>
            {employeeData.punchedIn ? `Hello, ${employeeData.name}!` : 'Punch In'}
          </Heading>
          {!employeeData.punchedIn && (
            <>
              <Text fontSize='lg' mt={2} fontWeight={employeeData.punchedIn ? 'normal' : 'bold'}>
                {employeeData.punchedIn
                  ? `Current Time: ${currentTime.toLocaleTimeString()}`
                  : 'Click "Clock In" to START your shift'}
              </Text>
              <Text fontSize='md' mt={2}>
                Current Time: {currentTime.toLocaleTimeString()}
              </Text>
            </>
          )}
          {employeeData.punchedIn && (
            <>
              <Text fontSize='md' mt={2}>
                Current Time: {currentTime.toLocaleTimeString()}
              </Text>
              <Text fontSize='md' mt={2} fontWeight="bold">
                Working Time: {formatTimer(timer)} Hours
              </Text>
            </>
          )}
          <Button
            variant='outline'
            colorScheme='teal'
            width='full'
            mt={6}
            onClick={onPunch}
          >
            {employeeData.punchedIn ? 'Clock Out' : 'Clock In'}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};


export default PunchPage;
