import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  IconButton,
  useColorMode,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { TiPlus } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateRewards,
  getAwardsAndRewards,
} from "../../Redux/AppReducer/Action";
import { FiEdit2, FiSave, FiTrash2 } from "react-icons/fi";

const AwardsAndRewards = () => {
  const [awardsAndRewards, setAwardsAndRewards] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [awardToDelete, setAwardToDelete] = useState(null);
  const [awardToEditIndex, setAwardToEditIndex] = useState(null);
  const { colorMode } = useColorMode();
  const toast = useToast();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllRewards = useSelector((state) => state.app.GETAWARDSANDREWARDSs);
  console.log(getAllRewards);

  const [editIndex, setEditIndex] = useState(null);
const [editedAward, setEditedAward] = useState({ title: "", description: "", date: "" });

const handleEditClick = (index) => {
  setEditIndex(index);
  setEditedAward(getAllRewards?.rewards[index]);
};

const handleSaveClick = (index) => {
  // Logic to save the edited award
  setEditIndex(null);
  
  toast({
    title: "Awards updated.",
    status: "success",
    duration: 2000,
    isClosable: true,
  });
};

  useEffect(() => {
    dispatch(getAwardsAndRewards(token));
  }, [dispatch, token]);

  const [newAward, setNewAward] = useState({
    title: "",
    description: "",
    date: "",
  });

  const [editAward, setEditAward] = useState({
    title: "",
    description: "",
    date: "",
  });

  const handleDeleteClick = (index) => {
    setAwardToDelete(index);
    setIsDeleteOpen(true);
  };

  const confirmDelete = () => {
    setAwardsAndRewards(awardsAndRewards.filter((_, i) => i !== awardToDelete));
    setIsDeleteOpen(false);
    setAwardToDelete(null);
    toast({
      title: "Award deleted.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const handleCreate = () => {
    const { title, description, date } = newAward;
    if (!newAward.title || !newAward.description || !newAward.date) {
      toast({
        description: "All fields are required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setAwardsAndRewards([...awardsAndRewards, newAward]);
    setNewAward({ title: "", description: "", date: "" });
    onClose();
    toast({
      title: "Award created.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    const displayAlert = (message) => {
      alert(message);
    };

    const CreateReward = { title, description, date };
    dispatch(CreateRewards(token, newAward)).then(() => {
      displayAlert("Details Updated Successfully!");
      dispatch(getAwardsAndRewards(token)).then(() => {
        setNewAward(getAllRewards);
      });
    });
  };



  return (
    <Box p={5} mt="70px" ml="280px" color="black" background={colorMode === "dark" ? "dark.700" : "#fcf7d2"}>
      {" "}
      <Flex justify="space-between" align="center" mb={5}>
        <Heading
          fontSize={25}
          mb={"20px"}
          ml={4}
          pt={4}
          color={colorMode === "dark" ? "white" : "#000000"}
          textAlign="left"
        >
          Awards And Rewards
        </Heading>
        <Button background="#e04141" color={"#FECE01"} onClick={onOpen}>
          <TiPlus />
          Create
        </Button>
      </Flex>
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        ml={4}
        mr={4}
        boxShadow= {colorMode === "dark" ? "rgba(105, 105, 105, 1) 4px 4px 4px 4px, rgba(105, 105, 105, 0.5) 4px 4px 16px 4px" : "rgba(14, 30, 37, 0.12) 4px 4px 4px 4px, rgba(14, 30, 37, 0.32) 4px 4px 16px 4px"}

        background={colorMode === "dark" ? "dark.700" : "#fae8e1"}
        // bg={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14} >
                Title
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Description
              </Th>
              <Th border="2px solid #e04141" color={"#e04141"} fontSize={14}>
                Date
              </Th>
              
              <Th
                border="2px solid #e04141"
                color={"#e04141"}
                fontSize={14}
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
  {getAllRewards?.rewards?.map((award, index) => (
    <Tr key={index}>
      <Td
        color={colorMode === "dark" ? "white" : "black"}
        border="2px solid #e04141"
        maxW={"200px"}
      >
        {editIndex === index ? (
          <Input
            value={editedAward.title}
            onChange={(e) =>
              setEditedAward({ ...editedAward, title: e.target.value })
            }
          />
        ) : (
          award.title
        )}
      </Td>
      <Td
        color={colorMode === "dark" ? "white" : "black"}
        border="2px solid #e04141"
      >
        {editIndex === index ? (
          <Textarea
            value={editedAward.description}
            onChange={(e) =>
              setEditedAward({ ...editedAward, description: e.target.value })
            }
          />
        ) : (
          <Textarea isReadOnly value={award.description} size="sm" />
        )}
      </Td>
      <Td
        color={colorMode === "dark" ? "white" : "black"}
        border="2px solid #e04141"
      >
        {editIndex === index ? (
          <Input
            type="date"
            value={editedAward.date}
            onChange={(e) =>
              setEditedAward({ ...editedAward, date: e.target.value })
            }
          />
        ) : (
          formatDate(award.date)
        )}
      </Td>
      <Td border="2px solid #e04141">
        {editIndex === index ? (
          <IconButton
            icon={<Box as={FiSave} color="green.500" />}
            aria-label="Save"
            onClick={() => handleSaveClick(index)}
            mr={2}
          />
        ) : (
          <IconButton
            icon={<Box as={FiEdit2} color="green.500" />}
            aria-label="Edit"
            onClick={() => handleEditClick(index)}
            mr={2}
          />
        )}
        <IconButton
          icon={<Box as={FiTrash2} color="red.500" />}
          aria-label="Delete"
          onClick={() => handleDeleteClick(index)}
        />
      </Td>
    </Tr>
  ))}
</Tbody>
        </Table>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt={"150px"}>
          <ModalHeader>Add Award</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                value={newAward.title}
                onChange={(e) =>
                  setNewAward({ ...newAward, title: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={newAward.description}
                onChange={(e) =>
                  setNewAward({ ...newAward, description: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={newAward.date}
                onChange={(e) =>
                  setNewAward({ ...newAward, date: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleCreate}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <ModalOverlay />
        <ModalContent
          mt={"250px"}
          bg={colorMode === "dark" ? "gray.800" : "white"}
        >
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this award?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AwardsAndRewards;
